import React from 'react';
import { Link } from '@reach/router';

import { Alert, AlertType, Box } from 'DesignSystem/Components';
import { ErrorsMap } from 'components/publisher/pages/Review/Summary/ErrorList';
import styles from './review.module.css';

export const ReviewErrorsList: React.FC<{
  errors: string[];
  messagesMap: ErrorsMap;
}> = ({ errors, messagesMap }) => {
  return (
    <Box margin={[0, 0, 30, 0]}>
      {errors.map((err) => {
        const { text, link } = messagesMap[err] || { text: err, link: null };

        const alert = (
          <Alert
            type={AlertType.error}
            message={text}
            bgColor="red"
            enableIcon
          />
        );

        return (
          <Box key={err} margin={[0, 0, 15, 0]}>
            {link ? (
              <Link className={styles.ErrorMessageLink} to={link}>
                {alert}
              </Link>
            ) : (
              <>{alert}</>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export type ValidatorsType<T> = {
  [key: string]: (data: T) => boolean;
};

export type MessagesType = {
  [key: string]: string;
};

export type ValidationResult = { isValid: boolean; errors: Array<string> };
export type ValidateFunc<T> = (data: T) => ValidationResult;

/*
 example of validators object: 
{
  overflow: (data) => data.size > 999 // if true => invalid,
  not_correct_color: (data) => data.color !== 'black'
}

example of messages object:
{
  OVERFLOW: 'There is overflow',
  NOT_CORRECT_COLOR: 'Color is incorrect'
}
*/

export function useBasicValidator<T>(
  validators: ValidatorsType<T>,
  messages?: MessagesType
): { validate: ValidateFunc<T> } {
  const validate: ValidateFunc<T> = (data) => {
    const errors: Array<string> = [];

    Object.keys(validators).forEach((name) => {
      const callable = validators[name];
      if (data && callable(data))
        errors.push(messages ? messages[name.toUpperCase()] : name);
    });

    return { isValid: errors.length === 0, errors };
  };

  return { validate };
}

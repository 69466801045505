import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import { Flex } from 'DesignSystem/Layout/Flex';
import * as React from 'react';
import { MAIcon } from 'shared/MAIcon';
import * as Type from 'DesignSystem/Typography';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import styles from './journey-canvas-header.module.css';
import { CloudStatus } from './useJourneyPersistenceStatus';

type SaveButtonProps = {
  isSaving?: boolean;
  handleSave: () => void;
  status?: CloudStatus;
};

type PrimaryButtonProps = {
  disabled?: boolean;
  onClick: () => void;
  label?: string;
};

type CloseButtonProps = {
  handleClose: () => void;
};

export const SaveButton: React.FC<SaveButtonProps> = ({
  handleSave,
  status,
  isSaving,
}) => {
  let color = Type.color.greenFull;
  if (status !== CloudStatus.UpToDate) color = Type.color.redFull;
  return (
    <Flex className={styles.button}>
      <Flex>
        <Box padding={[0, 8, 0, 0]} className={styles.statusString}>
          {status === CloudStatus.UpToDate ? 'Saved' : 'Unsaved changes'}
        </Box>
        <Box padding={[1, 0, 0, 0]}>
          <Type.PageHeading block={false} color={color}>
            &bull;
          </Type.PageHeading>
        </Box>
      </Flex>
      {isSaving && (
        <Box padding={[3, 20, 0, 12]}>
          <LoadingSpinner size="small" />
        </Box>
      )}

      {!isSaving && (
        <Button
          disabled={status === CloudStatus.UpToDate}
          className={styles.saveButton}
          layoutOnly
          title="Save Draft"
          icon={<MAIcon className={styles.saveIcon} name="save_as" />}
          onClick={handleSave}
        />
      )}
    </Flex>
  );
};

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  disabled,
  onClick,
  label,
}) => {
  return (
    <Button
      className={styles.primaryButton}
      label={label || 'Publish'}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export const CloseButton: React.FC<CloseButtonProps> = ({ handleClose }) => {
  return (
    <Flex className={styles.button}>
      <Button
        title="Close"
        secondary
        borderless
        icon={<MAIcon name="close" />}
        onClick={handleClose}
      />
    </Flex>
  );
};

import React, { useEffect, useState } from 'react';
import { OptionType } from 'hooks/common';
import Select, {
  components,
  ValueType,
  MenuProps,
  OptionProps,
  StylesConfig,
} from 'react-select';
import { useProgram } from 'contexts/program';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import {
  DisabledSelect,
  selectComponents,
  selectStyles,
  selectTheme,
} from './Select';
import styles from './AudienceBuilder.module.css';

const birthDateOption = (value?: string) => {
  if (value === 'birthday') {
    return {
      value: 'birthday',
      label: 'Birthday',
      description: 'Birthday',
    };
  }
  return {
    value: 'birth_date',
    label: 'Birthday',
    description: 'Birthday',
  };
};

const anniversaryDates = (
  value?: string,
  newDateOperatorsEnabled?: boolean,
  dobFormat?: string
) => {
  if (newDateOperatorsEnabled) {
    const options = [
      {
        value: 'start_date',
        label: 'Start Date',
        description: 'Date employment started (YYYY-MM-DD).',
      },
      {
        value: 'startday',
        label: 'Anniversary',
        description:
          'Month and day of work anniversary, based on Start Date (MM-DD).',
      },
      {
        value: 'birthday',
        label: 'Birthday',
        description: 'Month and day of birth (MM-DD).',
      },
    ];

    if (dobFormat !== '%m-%d') {
      options.splice(2, 0, {
        value: 'birth_date',
        label: 'Date of Birth',
        description: 'Date of birth (YYYY-MM-DD).',
      });
      return options;
    }
    return options;
  }
  return [
    {
      value: 'start_date',
      label: 'Anniversary',
      description: 'Anniversary',
    },
    birthDateOption(value),
  ];
};

const profileOptions = (
  value?: string,
  newDateOperatorsEnabled?: boolean,
  dobFormat?: string
) => {
  return [
    ...anniversaryDates(value, newDateOperatorsEnabled, dobFormat),
    {
      value: 'business_unit',
      label: 'Business Unit',
      description: 'All users that are in a specific Business Unit.',
    },
    {
      value: 'locality',
      label: 'City / Locality',
      description: 'All users within a particular city or locality.',
    },
    {
      value: 'cost_center',
      label: 'Cost Center',
      description: 'All users that are in a specific Cost Center.',
    },
    {
      value: 'country',
      label: 'Country',
      description: 'All users in a specific Country.',
    },
    {
      value: 'department',
      label: 'Department',
      description: 'All users in a specific Department.',
    },
    {
      value: 'division',
      label: 'Division',
      description: 'All users in a specific Division.',
    },
    {
      value: 'employee_id',
      label: 'Employee ID',
      description: 'Users with a specific employee ID.',
    },
    {
      value: 'name',
      label: 'Employee Name',
      description: 'Find one or more employees by name.',
    },
    {
      value: 'employee_type',
      label: 'Employee Type',
      description:
        'Find a selection of employees by type such as full time, part time, or contractor.',
    },
    {
      value: 'gender',
      label: 'Gender',
      description: 'Find users of a particular gender.',
    },
    {
      value: 'job_title',
      label: 'Job Title',
      description: 'All users with a specific Job Title.',
    },
    {
      value: 'locale',
      label: 'Language / Locale',
      description: 'All users that speak a particular language.',
    },
    {
      value: 'manager',
      label: 'Manager',
      description: 'All users with a particular manager.',
    },
    {
      value: 'organization',
      label: 'Organization',
      description: 'All users within a particular organization.',
    },
    {
      value: 'preferred_language',
      label: 'Preferred Language',
      description: 'All users with a specific prefered language.',
    },
    {
      value: 'promotion_date',
      label: 'Promotion Date',
      description: 'Date of promotion (YYYY-MM-DD).',
    },
    {
      value: 'region',
      label: 'State / Region',
      description: 'All users within a particular state or region.',
    },
    {
      value: 'requisition_approval_date',
      label: 'Requisition Approval Date',
      description: 'Date role or position was approved (YYYY-MM-DD).',
    },
    {
      value: 'timezone',
      label: 'Timezone',
      description: 'All users in a particular time zone.',
    },
    {
      value: 'fid',
      label: 'Universal Identifier',
      description: 'Select one or more users by universal identifier.',
    },
    {
      value: 'username',
      label: 'Username',
      description: 'Select one or more users by username.',
    },
    {
      value: 'work_location',
      label: 'Work Location',
      description: 'All users that are in a specific work location.',
    },
  ];
};

const permissionSection = (permissionEnabled = false) => {
  if (permissionEnabled) {
    return {
      label: 'Permissions',
      options: [
        {
          value: 'roles',
          label: 'Roles',
          description:
            'A user’s community role indicates the level of permission to access your community - Experience only (Member) or Experience and Studio (Content Creator, Content Publisher, Community Admin, Brand Super Admin, any custom roles)',
        },
        {
          value: 'classic_role',
          label: 'Classic Role',
          description:
            "A user's program role indicates the level of permission to access your program - Experience only (Member) or Experience and Studio (Analyst, Topic Manager, Publisher, Community Manager, Administrator)",
        },
      ],
    };
  }

  return {
    label: 'Permissions',
    options: [
      {
        value: 'role',
        label: 'Role',
        description:
          "A user's program role indicates the level of permission to access your program - Experience only (Member) or Experience and Studio (Analyst, Topic Manager, Publisher, Community Manager, Administrator)",
      },
    ],
  };
};

const sections = [
  {
    label: 'Audience',
    options: [
      {
        value: 'group',
        label: 'Audience Name',
        description: 'Find one or more audiences by name.',
      },
    ],
  },
  {
    label: 'Status',
    options: [
      {
        value: 'experience_status',
        label: 'Experience Status',
        description: "The user's experience status.",
      },
      {
        value: 'provisioning_status',
        label: 'Provisioning Status',
        description: "The user's provisioning status.",
      },
    ],
  },
  {
    label: 'Email',
    options: [
      {
        value: 'email',
        label: 'Email',
        description:
          'Users with an email, primary or not, that matches any of the criteria chosen. Note, only Primary email values are used in communications.',
      },
      {
        value: 'primary_email',
        label: 'Primary Email',
        description:
          'Users with a primary email that matches any of the audience criteria. Note Primary emails are used to send communications.',
      },
      {
        value: 'verified_email',
        label: 'Verified Email',
        description:
          'An email supplied by the organization or supplied by the user and confirmed via email confirmation.',
      },
      {
        value: 'email_domain',
        label: 'Email Domain',
        description: 'The domain of an email',
      },
    ],
  },
  {
    label: 'Mobile App',
    options: [
      {
        value: 'mobile_app.id',
        label: 'Mobile App ID',
        description: 'Target users by mobile app ID',
      },
      {
        value: 'mobile_app.identifier',
        label: 'Mobile App Identifier',
        description: 'Target users by mobile app identifier',
      },
      {
        value: 'mobile_app.name',
        label: 'Mobile App Name',
        description: 'Target users by mobile app name',
      },
      {
        value: 'mobile_app.platform',
        label: 'Mobile App Platform',
        description: 'Target users by mobile app platform, iOS or Android',
      },
      {
        value: 'mobile_app.version',
        label: 'Mobile App Version',
        description: 'Target users by a specific app version',
      },
    ],
  },
  {
    label: 'Reach',
    options: [
      {
        value: 'reachable',
        label: 'Reachable',
        description: 'All reachable users.',
      },
      {
        value: 'reachable_by_desktop',
        label: 'Reachable via Desktop',
        description: 'All users that are reachable via the Desktop Experience.',
      },
      {
        value: 'reachable_by_email',
        label: 'Reachable via Email',
        description:
          'All users that are reachable by email. These are users with a verified email.',
      },
      {
        value: 'reachable_by_mobile_app',
        label: 'Reachable via Mobile App',
        description:
          'All users reachable by mobile app. These are users that are in status Registered and also have a session on a mobile app.',
      },
      {
        value: 'reachable_by_push_notification',
        label: 'Reachable via Push Notification',
        description:
          'All users reachable by push notification. These are users that are in status Registered and also have a push notification enabled.',
      },
    ],
  },
  {
    label: 'Topics',
    options: [
      {
        value: 'topics.name',
        label: 'Topic Name',
        description: 'Find all users that are following a particular topic',
      },
      {
        value: 'topics.followed',
        label: 'Topics Followed',
        description: 'Find users by the number of topics they follow',
      },
    ],
  },
  {
    label: 'User Activity',
    options: [
      {
        value: 'created_at',
        label: 'Created Date',
        description: 'User accounts created in a particular time frame',
      },
      {
        value: 'activated_at',
        label: 'Registered Date',
        description:
          'Users that registered for Experience in a particular time frame',
      },
      {
        value: 'blocked_at',
        label: 'Inactivated Date',
        description: 'Users that were inactivated in a particular time frame',
      },
    ],
  },
  {
    label: 'Shares',
    options: [
      {
        value: 'shares.count.all_time',
        label: 'Count all time',
        description:
          "Find users by the number of shares they've completed in all time",
      },
      {
        value: 'shares.count.last_month',
        label: 'Count last month',
        description:
          "Find users by the number of shares they've completed in the last month",
      },
      {
        value: 'shares.count.last_quarter',
        label: 'Count last quarter',
        description:
          "Find users by the number of shares they've completed in the last quarter",
      },
      {
        value: 'shares.count.last_week',
        label: 'Count last week',
        description:
          "Find users by the number of shares they've completed in the last week",
      },
      {
        value: 'shares.count.last_year',
        label: 'Count last year',
        description:
          "Find users by the number of shares they've completed in the last year",
      },
      {
        value: 'shares.count.this_month',
        label: 'Count this month',
        description:
          "Find users by the number of shares they've completed this month",
      },
      {
        value: 'shares.count.this_quarter',
        label: 'Count this quarter',
        description:
          "Find users by the number of shares they've completed this quarter",
      },
      {
        value: 'shares.count.this_week',
        label: 'Count this week',
        description:
          "Find users by the number of shares they've completed this week",
      },
      {
        value: 'shares.count.this_year',
        label: 'Count this year',
        description:
          "Find users by the number of shares they've completed this year",
      },
    ],
  },
  {
    label: 'Channels',
    options: [
      {
        value: 'preferred_channel',
        label: 'Preferred Channel',
        description:
          'The channel with the most activity by the user over the last 90 days',
      },
      {
        value: 'usage_location',
        label: 'Usage Location',
        description: 'Users in a particular location, generated by IP',
      },
    ],
  },
];

export function isRecognizedCriterion(
  v: string,
  newDateOperatorsEnabled?: boolean,
  dobFormat?: string
): boolean {
  if (dobFormat === '%m-%d' && v === 'birth_date') return false;
  return (
    sections.some((section) =>
      section.options.some((opt) => opt.value === v)
    ) ||
    profileOptions(v, newDateOperatorsEnabled, dobFormat).some(
      (opt) => opt.value === v
    ) ||
    v.split('.')[0] === 'custom' ||
    ['roles', 'role', 'classic_role'].includes(v) ||
    isDateCriterion(v)
  );
}

export function isDateCriterion(
  criterionName: string | undefined,
  criterionType?: string | undefined
): boolean {
  if (
    criterionType === 'criteria/timestamp' &&
    criterionName?.startsWith('custom.')
  ) {
    return true;
  }

  switch (criterionName) {
    case 'start_date':
    case 'startDate':
    case 'startday':
    case 'birth_date':
    case 'birthDate':
    case 'birthday':
    case 'createdAt':
    case 'activatedAt':
    case 'blockedAt':
    case 'created_at':
    case 'activated_at':
    case 'blocked_at':
    case 'promotion_date':
    case 'requisition_approval_date':
      return true;
    default:
      return false;
  }
}

/**
 * just the date part, no time
 */
export function isDayOnlyNoTimezoneCriterion(
  criterion: string | undefined
): boolean {
  switch (criterion) {
    case 'birthday':
    case 'birth_date':
    case 'birthdate':
    case 'birthDate':
    case 'startday':
      return true;
    default:
      return false;
  }
}

export function isDateOnlyNoTimezoneCriterion(
  criterion: string | undefined
): boolean {
  switch (criterion) {
    case 'start_date':
    case 'startDate':
    case 'promotion_date':
    case 'promotionDate':
    case 'requisition_approval_date':
    case 'requisitionApprovalDate':
      return true;
    default:
      return false;
  }
}

const Menu: React.FC<MenuProps<{ value: string; label: string }>> = (props) => {
  const {
    children,
    selectProps: { hoveredValue, attributes },
  } = props;
  const criteriaDescriptions = attributes.map(
    (section: { label: string; options: Array<OptionType> }) => {
      return section.options.map(
        (option: OptionType) =>
          hoveredValue === option.value && (
            <div key={option.value}>
              <h2>{option.label}</h2>
              <p>{option.description}</p>
            </div>
          )
      );
    }
  );

  return (
    <div>
      <div className={styles.offsetSpacer} />
      <div className={styles.menuWrapper}>
        <div className={styles.menuItemsWrapper}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <components.Menu {...props}>{children}</components.Menu>
        </div>
        <div className={styles.permissionDesc}>{criteriaDescriptions}</div>
      </div>
    </div>
  );
};

const Option: React.FC<OptionProps<OptionType>> = (props) => {
  const {
    children,
    data,
    isFocused,
    selectProps: { setHoveredValue },
  } = props;
  if (isFocused) {
    setHoveredValue(data.value);
  }
  return (
    <div onMouseEnter={() => setHoveredValue(data.value)}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <components.Option {...props}>{children}</components.Option>
    </div>
  );
};

const customStyles: StylesConfig = {
  ...selectStyles,
  control: (presetStyles: React.CSSProperties) => ({
    ...presetStyles,
    backgroundColor: 'var(--color-gray00)',
    cursor: 'pointer',
  }),
  menu: () => ({
    backgroundColor: 'var(--color-gray00)',
    maxHeight: '300px',
    width: '100%',
  }),
  option: (presetStyles: React.CSSProperties) => ({
    ...presetStyles,
    cursor: 'pointer',
  }),
};

export const filterCriteria = (
  data: Array<{ id: string; type: string }> | undefined,
  permissionEnabled = false,
  value?: string,
  newDateOperatorsEnabled = false,
  dobFormat?: string
): Array<{ label: string; options: Array<OptionType> }> => {
  const keys = data?.map((n) => n.id);
  const customOptions = data
    ?.filter((n) => n.id.split('.')[0] === 'custom')
    .map((n) => {
      const str = n.id.split('.')[1].replace(/_/g, ' ');
      return {
        value: n.id,
        label: str[0].toUpperCase() + str.substring(1),
        description:
          'This is a custom attribute provided by your organization.',
      };
    });

  const customAttributes = {
    label: 'Custom Attributes',
    options: customOptions || [],
  };

  const profileAttributes = {
    label: 'Profile',
    options:
      profileOptions(value, newDateOperatorsEnabled, dobFormat).filter((n) =>
        keys?.includes(n.value)
      ) || [],
  };

  if (customAttributes.options.length === 0)
    return [
      profileAttributes,
      permissionSection(permissionEnabled),
      ...sections,
    ];
  return [
    profileAttributes,
    permissionSection(permissionEnabled),
    ...sections,
    customAttributes,
  ];
};

export const CriterionSelect: React.FC<{
  value?: string;
  onChange: (value?: string) => void;
  isDisabled?: boolean;
  availableCriteria: Array<{ id: string; type: string }>;
  newDateOperatorsEnabled?: boolean;
  dobFormat?: string;
}> = ({
  value,
  onChange,
  isDisabled,
  availableCriteria,
  newDateOperatorsEnabled,
  dobFormat,
}) => {
  const { id: programId } = useProgram();
  const permissionsEnabled =
    (useFeatureFlagsQuery(programId, 'Studio.Permissions.Service').data
      ?.value as boolean) || false;
  const [hoveredValue, setHoveredValue] = useState('');
  const attributes = filterCriteria(
    availableCriteria,
    permissionsEnabled,
    value,
    newDateOperatorsEnabled,
    dobFormat
  );

  const optionValue = attributes
    .map((section) => section.options)
    .flat()
    .find((option) => option.value === value);
  const onOptionChange = (option: ValueType<OptionType>) => {
    setHoveredValue((option as OptionType).value);
    onChange((option as OptionType).value);
  };

  useEffect(() => {
    if (optionValue) {
      setHoveredValue(optionValue.value);
    }
  }, [optionValue]);

  if (
    value &&
    !isRecognizedCriterion(value, newDateOperatorsEnabled, dobFormat)
  ) {
    return <DisabledSelect placeholder={value} />;
  }

  return (
    <Select
      aria-label="Audience Builder Attribute"
      options={attributes}
      attributes={attributes}
      components={{
        ...selectComponents,
        Menu,
        Option,
      }}
      placeholder="Type to search for attribute"
      styles={customStyles}
      hoveredValue={hoveredValue}
      isDisabled={isDisabled}
      setHoveredValue={setHoveredValue}
      value={optionValue || null}
      onChange={onOptionChange}
      theme={selectTheme}
    />
  );
};

import { useMemo, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useProgram } from 'contexts/program';
import {
  Category,
  Image,
  Item,
  Blocks,
  Template,
  defaultTemplate,
  unlistItems,
  LibraryBlocksLibrary,
  LibraryCategory,
  LibraryImage,
  FontStylesheet,
  UpsertableItem,
  defaultFontStylesheet,
  CustomBlock,
} from 'models/library';
import { Content } from 'models/content';
import {
  fetchItems,
  fetchCategories,
  createLibraryItem,
  updateLibraryItem,
  UpsertType,
  getTemplate,
  ApiLibraryItemResponse,
  enableItemForProgram,
  disableItemForProgram,
  CategoryUpsertType,
  updateLibraryCategory,
  createLibraryCategory,
  ApiLibraryCategoryResponse,
  getFont,
  LibraryItemsParams,
} from 'services/api-library';
import { ContentFilterFetchProps } from 'components/content/ContentFilterBar/ContentFilterBar';
import { ProgramIdProp } from 'services/api-insights';
import { FiltersStateType } from 'contexts/content/filters';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { DefinitionBlock, postHasPoll } from 'models/publisher/block';
import { useContentsInfiniteQuery } from 'hooks/content';
import {
  useApiQuery,
  useInfiniteApiQuery,
  QueryResponse,
  InfiniteQueryResponse,
  QueryError,
  MutationResponse,
} from 'hooks/common';
import { DateTime } from 'luxon';
import {
  updateCallToAction,
  updateFirstNotification,
} from 'models/publisher/post';
import { TemplateContainerType } from 'models/template';
import { resolveBlocks } from 'services/api-content-blocks';
import { Layout } from 'models/publisher/format';
import { useFeatureFlagsQuery } from './feature-flags';

function mapServerDataToTemplate(data: ApiLibraryItemResponse) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const record: { attributes: Record<string, any> } = data.data;
  const { publishedAt, archiveAt, asset } = record.attributes;

  const post = updateCallToAction({ ...asset.template });
  const newPost = updateFirstNotification(post);

  return ({
    ...record.attributes,
    publishedAt: publishedAt && DateTime.fromISO(publishedAt as string),
    archiveAt: archiveAt && DateTime.fromISO(archiveAt as string),
    asset: {
      template: newPost,
    },
  } as unknown) as Template;
}

function mapServerDataToImage(data: ApiLibraryItemResponse) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const record: { attributes: Record<string, any> } = data.data;

  return ({
    ...record.attributes,
  } as unknown) as LibraryImage;
}

function mapServerDataToFontStylesheet(data: ApiLibraryItemResponse) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const record: { attributes: Record<string, any> } = data.data;

  return ({
    ...record.attributes,
  } as unknown) as FontStylesheet;
}

function mapServerDataToBlock(data: ApiLibraryItemResponse) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const record: { attributes: Record<string, any> } = data.data;

  return ({
    ...record.attributes,
  } as unknown) as CustomBlock;
}

function mapServerDataToLibraryCategory(data: ApiLibraryCategoryResponse) {
  return ({
    id: data.data.id,
    title: data.data.attributes.title,
    description: data.data.attributes.description,
    program_id: data.data.attributes.program_id,
    library_type: data.data.attributes.library_type,
    identifier: data.data.attributes.identifier,
  } as unknown) as LibraryCategory;
}

function useLibraryItems<T extends Item>(
  type: T['type'],
  params: LibraryItemsParams
): InfiniteQueryResponse<T> {
  const { id: programId } = useProgram();
  const key = `library::items::${type}`;
  const response = useInfiniteApiQuery(key, fetchItems, {
    programId,
    type,
    filter: params.filter,
    sortBy: params.sortBy,
    pageSize: params.pageSize || 100,
    includeDisabled: params.includeDisabled,
    onlyDisabled: params.onlyDisabled,
    permittedToUseOnly: params.permittedToUseOnly,
    restrictedOnly: params.restrictedOnly,
    excludeCategoriesIds: params.excludeCategoriesIds,
  });
  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries([key]);
  }, [key, queryClient]);
  return {
    ...response,
    invalidateQuery,
    data: (response.data.map((record) => ({
      id: record.id,
      ...record.attributes,
    })) as unknown[]) as T[],
  };
}

export const useLibraryImages = (
  params: Pick<LibraryItemsParams, 'filter'>
): InfiniteQueryResponse<Image> => {
  return useLibraryItems('content_image', params);
};

export const useLibraryBlocks = (
  params: Pick<LibraryItemsParams, 'excludeCategoriesIds' | 'filter'>
): InfiniteQueryResponse<Blocks> => {
  const hiddenPollBlocks = !!useFeatureFlag('hiddenPollBlocks');
  const response = useLibraryItems<Blocks>('content_blocks', params);
  const { id: programId } = useProgram();
  const htmlTemplatesEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Publish.HTMLTemplates'
  ).data?.value;
  const boxIntegrationEnabled = useFeatureFlagsQuery(
    programId,
    'License.Integration.BoxKnowledgeManagement'
  ).data?.value;

  const data = unlistItems(response.data || [], {
    removePolls: hiddenPollBlocks,
    removeCustomHtml: !htmlTemplatesEnabled,
    removeBoxIntegration: !boxIntegrationEnabled,
  });
  return { ...response, data };
};

export const useLibraryTemplates = (
  params: Omit<LibraryItemsParams, 'onlyDisabled'> & {
    isJourneyTemplates?: boolean;
  }
): InfiniteQueryResponse<Template> => {
  return useLibraryItems(
    params.isJourneyTemplates ? 'journey_template' : 'template',
    params
  );
};

export const useLibraryFonts = (
  params: Omit<LibraryItemsParams, 'onlyDisabled' | 'sortBy'>
): InfiniteQueryResponse<FontStylesheet> => {
  return useLibraryItems('font_stylesheet', params);
};

export const useLibraryContents = (
  fetchProps: ContentFilterFetchProps & ProgramIdProp,
  filters: FiltersStateType
): InfiniteQueryResponse<Content> => {
  const query = useContentsInfiniteQuery(fetchProps, filters);
  const posts: Content[] = (query.data
    .map((page) =>
      page.data
        .map((post) => {
          // TODO: Deserialize ContentCommunication here. Esp Priority (name) => Priority obj

          // So far this only shows content, not old Polls. New polls will be content
          if (post.type !== 'content_planner') return null;
          // Just in case, we'll filter away any that are missing a permalink.
          // (After all, a post without a URL will be hard to point a link to.)
          if (post.permalinkUrl === '') return null;
          return post;
        })
        .filter((post) => post !== null)
    )
    .flat() as unknown[]) as Content[];
  return {
    ...query,
    data: posts,
  };
};

function useLibraryCategories<T extends Item>(
  type: T['type']
): QueryResponse<Category[]> {
  const { id: programId } = useProgram();
  const queryClient = useQueryClient();
  const hiddenPollBlocks = !!useFeatureFlag('hiddenPollBlocks');
  const response = useApiQuery(
    `library::categories::${type}`,
    fetchCategories,
    {
      programId,
      type,
    }
  );
  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries(`library::categories::${type}`);
  }, [type, queryClient]);

  return {
    ...response,
    invalidateQuery,
    data: response.data?.data
      .filter((record) =>
        hiddenPollBlocks ? record.attributes.title !== 'Polls' : record
      )
      .map((record) => mapServerDataToLibraryCategory({ data: record })),
  };
}

export const useLibraryImageCategories = (): QueryResponse<Category[]> => {
  return useLibraryCategories('content_image');
};

export const useLibraryBlockCategories = (): QueryResponse<Category[]> => {
  return useLibraryCategories('content_blocks');
};

export const useLibraryContentCategories = (): QueryResponse<Category[]> => {
  // TODO in the future, this will look up topics to use and filter on.
  return {
    isLoading: false,
    data: [],
  };
};

const mapLibraryTypes = {
  content_image: mapServerDataToImage,
  template: mapServerDataToTemplate,
  journey_template: mapServerDataToTemplate,
  font_stylesheet: mapServerDataToFontStylesheet,
  content_blocks: mapServerDataToBlock,
};

async function upsert(props: UpsertType) {
  let data: ApiLibraryItemResponse;

  if (props.item.id !== 'new') {
    data = await updateLibraryItem(props);
  } else {
    data = await createLibraryItem(props);
  }

  if (!data) throw new Error('Error while upserting library item');
  const fn = mapLibraryTypes[data.data.attributes.type];
  return { ...fn(data), id: data.data.id };
}

async function upsertCategory(props: CategoryUpsertType) {
  let data: ApiLibraryCategoryResponse;

  if (props.item.id) {
    data = await updateLibraryCategory(props);
  } else {
    data = await createLibraryCategory(props);
  }

  if (!data) throw new Error('Error while upserting library category');
  return { ...mapServerDataToLibraryCategory(data), id: data.data.id };
}

type LibraryMutation = {
  onSuccess: (
    item: Template | LibraryImage | CustomBlock | FontStylesheet
  ) => void;
  onError?: (error: QueryError) => void;
};

type LibraryCategoryMutation = {
  onSuccess: (category: LibraryCategory) => void;
  onError?: (error: QueryError) => void;
};

export const useLibraryMutation = ({
  onSuccess,
  onError,
}: LibraryMutation): MutationResponse<UpsertType> => {
  const { mutate, isLoading, error } = useMutation(['library/save'], upsert, {
    onSuccess,
    onError,
  });
  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

export const useLibraryCategoryMutation = ({
  onSuccess,
  onError,
}: LibraryCategoryMutation): MutationResponse<CategoryUpsertType> => {
  const { mutate, isLoading, error } = useMutation(
    ['libraryCategory/save'],
    upsertCategory,
    {
      onSuccess,
      onError,
    }
  );
  return { mutate, isSaving: isLoading, errorMessage: error?.message };
};

export const useLibraryEnable = (
  onSuccess: (item: UpsertableItem) => void
): {
  mutate: (item: UpsertableItem) => void;
  isWorking: boolean;
  errorMessage?: string;
} => {
  const { id: programId } = useProgram();
  const { mutate, isLoading, error } = useMutation(
    async (item: UpsertableItem) => {
      const data = await enableItemForProgram({ programId, item });
      if (onSuccess) {
        const fn = mapLibraryTypes[data.data.attributes.type];
        onSuccess({ ...fn(data), id: data.data.id });
      }
    }
  );
  return {
    mutate,
    isWorking: isLoading,
    errorMessage: (error as QueryError)?.message,
  };
};

export const useLibraryDisable = (
  onSuccess: (item: UpsertableItem) => void
): {
  mutate: (item: UpsertableItem) => void;
  isWorking: boolean;
  errorMessage?: string;
} => {
  const { id: programId } = useProgram();
  const { mutate, isLoading, error } = useMutation(
    async (item: UpsertableItem) => {
      const data = await disableItemForProgram({ programId, item });
      if (onSuccess) {
        const fn = mapLibraryTypes[data.data.attributes.type];
        onSuccess({ ...fn(data), id: data.data.id });
      }
    }
  );

  return {
    mutate,
    isWorking: isLoading,
    errorMessage: (error as QueryError)?.message,
  };
};

export const useTemplateQuery = (
  onSuccess: (template: Template) => void,
  containerType: TemplateContainerType,
  failureCallback: () => void
): {
  mutate: (id: number | 'new') => void;
  isLoading: boolean;
  errorMessage?: string;
} => {
  const { id: programId } = useProgram();

  const { mutate, isLoading, error } = useMutation(
    async (id: number | 'new') => {
      if (id === 'new') {
        onSuccess({
          ...defaultTemplate,
        });
      } else {
        const data = await getTemplate({
          programId,
          templateId: id,
          containerType,
        });

        if (onSuccess) {
          onSuccess({ ...mapServerDataToTemplate(data), id: data.data.id });
        }
      }
    },
    {
      onError() {
        failureCallback();
      },
    }
  );
  return { mutate, isLoading, errorMessage: (error as QueryError)?.message };
};

export const useFontQuery = (
  onSuccess: (font: FontStylesheet) => void
): {
  mutate: (id: number | 'new') => void;
  isLoading: boolean;
  errorMessage?: string;
} => {
  const { id: programId } = useProgram();

  const { mutate, isLoading, error } = useMutation(
    async (id: number | 'new') => {
      if (id === 'new') {
        onSuccess({
          ...defaultFontStylesheet,
        });
      } else {
        const data = await getFont({
          programId,
          fontId: id,
        });

        if (onSuccess) {
          onSuccess({
            ...mapServerDataToFontStylesheet(data),
            id: data.data.id,
          });
        }
      }
    }
  );
  return { mutate, isLoading, errorMessage: (error as QueryError)?.message };
};

export function useLibrary(config?: {
  excludeCategoriesIds: number[];
}): LibraryBlocksLibrary {
  const hiddenPollBlocks = !!useFeatureFlag('hiddenPollBlocks');
  const { data } = useLibraryBlocks({
    filter: { type: 'all' },
    excludeCategoriesIds: config?.excludeCategoriesIds,
  });
  return useMemo(() => {
    const memo: LibraryBlocksLibrary = {};
    const blocks = hiddenPollBlocks
      ? data.filter((block) => !postHasPoll({ blocks: block.asset.blocks }))
      : data;
    blocks.forEach((block) => {
      memo[block.identifier] = block;
    });
    return memo;
  }, [data, hiddenPollBlocks]);
}

export function useCreateTemplateFromLibrary(): (
  t?: string
) => Promise<Template> {
  const library = useLibrary();
  const { id: programId } = useProgram();

  const create = useCallback(
    async (t?: string) => {
      let result = defaultTemplate;
      if (!t) {
        result = defaultTemplate;
      } else {
        const libraryTemplate = library[t];
        if (libraryTemplate) {
          let blocks = libraryTemplate.asset?.blocks || [];
          if (t === 'custom_html')
            blocks = blocks.map((block) =>
              block.name === 'custom_html'
                ? { ...block, format_data: { layout: Layout.FullWidth } }
                : block
            );
          const resolvedBlocks = await resolveBlocks(programId, blocks);
          result = {
            ...defaultTemplate,
            asset: {
              ...defaultTemplate.asset,
              template: {
                ...defaultTemplate.asset.template,
                blocks: (resolvedBlocks as unknown) as DefinitionBlock[],
              },
            },
          };
        }
      }

      return result;
    },
    [library, programId]
  );

  return create;
}

import React, { RefObject, useState } from 'react';
import {
  useAudienceBulkArchive,
  useAudienceBulkUnArchive,
  useFavoriteAudiencesQuery,
} from 'hooks/audience';
import { AudienceBulkActionFilters, BulkSelection } from 'hooks/common';
import { Audience } from 'models/audience';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { BulkSelectWrapper } from 'shared/BulkSelect/Wrapper';
import { InfiniteBannerList } from 'shared/InfiniteBannerList';
import { BulkSelectActionType } from 'shared/BulkSelect/Actions';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { removeStandardRoleAudiences } from 'utility/audienceUtil';
import { AudiencesListItem } from './AudienceListItem';

type PropsType = {
  audiences: Array<Audience>;
  isLoading?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  height: number;
  parentRef: RefObject<HTMLDivElement>;
  totalRecords?: number;
  setIsBulkSelectMode?: (isBulkSelectMode: boolean) => void;
  filterConfig: AudienceBulkActionFilters;
};

export const AudiencesList: React.FC<PropsType> = (props) => {
  const {
    audiences,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    totalRecords,
    parentRef,
    height,
    filterConfig,
  } = props;
  const { id: programId } = useProgram();
  const {
    permissions: { manageAudienceAccess },
  } = usePermissions();
  const showStandardRoles = !!useFeatureFlagsQuery(
    programId,
    'Studio.Permissions.ShowStandardRoles'
  ).data?.value;
  let filteredAudiences = audiences;
  if (!showStandardRoles) {
    filteredAudiences = removeStandardRoleAudiences(filteredAudiences);
  }
  const itemCount = filteredAudiences.length || 0;
  const { data: favoriteAudiences } = useFavoriteAudiencesQuery(programId);
  const { archive } = useAudienceBulkArchive(programId);
  const { unArchive } = useAudienceBulkUnArchive(programId);
  const setIsBulkSelectMode = useState(false)[1];

  const actions = manageAudienceAccess
    ? ([
        {
          type: 'button',
          disabled: true,
          // eslint-disable-next-line prettier/prettier
          scimIds: audiences.filter((a) => a.type === 'scim').map((a) => a.id),
          actions: [
            {
              label: 'Archive',
              iconName: 'archive',
              onClick: (bulkSelection: BulkSelection) => {
                archive(bulkSelection, filterConfig);
                return true;
              },
            },
          ],
        },
        {
          type: 'button',
          actions: [
            {
              label: 'Unarchive',
              iconName: 'trash',
              onClick: (bulkSelection: BulkSelection) => {
                unArchive(bulkSelection, filterConfig);
                return true;
              },
            },
          ],
        },
      ] as BulkSelectActionType[])
    : [];

  return (
    <BulkSelectWrapper
      itemName="audience"
      totalRecords={totalRecords || 0}
      actions={actions}
      setIsBulkSelectMode={setIsBulkSelectMode}
    >
      <InfiniteBannerList
        itemCount={itemCount}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
        noItemsComponent={<div>No audiences found.</div>}
        parentRef={parentRef}
        height={height}
      >
        {(index: number) => (
          <AudiencesListItem
            audience={filteredAudiences[index]}
            favoriteAudiences={favoriteAudiences}
            key={`audience-${audiences[index].id}`}
          />
        )}
      </InfiniteBannerList>
    </BulkSelectWrapper>
  );
};

import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSearchBarQueryString } from 'hooks/useSearchBarQueryString';
import { useDebounce } from 'hooks/useDebounce';
import {
  useAudiencesInfiniteQuery,
  useAudienceTagsQuery,
} from 'hooks/audience';
import { OptionType } from 'hooks/common';
import { useProgram } from 'contexts/program';
import { usePermissions } from 'contexts/permissions';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { InfiniteContainer } from 'DesignSystem/Layout/ListContainers';
import { useGenericFilterDropdownQueryString } from 'hooks/useFilterDropdownQueryString';
import { Icon } from 'shared/Icon';
import { AudienceTag } from 'services/api-audiences';
import { AudiencesList } from './AudiencesList';
import { FiltersBar } from './FiltersBar';

const mapTagData = (tagsData: AudienceTag[] = []): OptionType[] => {
  const sortedTagData = tagsData?.sort(({ id: aId }, { id: bId }) =>
    aId > bId ? 1 : -1
  );

  return (
    sortedTagData?.map(({ id }: { id: string }) => ({
      label: id,
      value: id,
    })) || []
  );
};

export const AudiencesListPage: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const {
    permissions: { manageAudienceAccess },
  } = usePermissions();
  const [searchTerm, setSearchTerm] = useSearchBarQueryString();
  const [selectedTags, setSelectedTags] = useGenericFilterDropdownQueryString<
    string[]
  >({ key: 'tags' });
  const [
    selectedStatuses,
    setSelectedStatuses,
  ] = useGenericFilterDropdownQueryString<string[]>({
    key: 'statuses',
    defaultValue: ['enabled'],
  });
  const [selectedTypes, setSelectedTypes] = useGenericFilterDropdownQueryString<
    string[]
  >({ key: 'types' });
  const [isFavorites, setIsFavorites] = useGenericFilterDropdownQueryString<
    boolean
  >({
    key: 'isFavorites',
    boolean: true,
  });
  const [tagSearchTerm, setTagSearchTerm] = useState<string>('');
  const search = useDebounce(searchTerm);
  const tags = useDebounce(selectedTags);
  const status = useDebounce(selectedStatuses);
  const audienceType = useDebounce(selectedTypes);
  const {
    isLoading,
    data,
    meta,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    errorMessage,
  } = useAudiencesInfiniteQuery({
    programId,
    search,
    tags,
    statuses: status,
    types: audienceType,
    favorites: isFavorites,
  });
  const { data: tagsData } = useAudienceTagsQuery(programId);

  const mappedTagData = mapTagData(tagsData);
  const [tagOptions, setTagOptions] = useState(mappedTagData);
  const truncateFilterItems = (needle: string, filters: OptionType[] = []) => {
    return filters.filter(({ label }) => label.includes(needle));
  };

  const getQueryStringValueByKey = (key: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get(key);
    return value;
  };

  React.useEffect(() => {
    const initialStatuses = getQueryStringValueByKey('statuses')?.split(',');
    const initialTypes = getQueryStringValueByKey('types')?.split(',');
    const initialTags = getQueryStringValueByKey('tags')?.split(',');

    if (initialStatuses) setSelectedStatuses(initialStatuses);
    if (initialTypes) setSelectedTypes(initialTypes);
    if (initialTags) setSelectedTags(initialTags);
    // disabling the eslint rule because we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setTagOptions(truncateFilterItems(tagSearchTerm, mappedTagData));
    // eslint-disable-next-line
  }, [tagSearchTerm]);

  React.useEffect(() => {
    setTagOptions(mappedTagData);
    // eslint-disable-next-line
  }, [tagsData]);

  const parentRef = React.useRef<HTMLDivElement>(null);

  // eslint-disable-next-line
  const filterChangeCallbacks: { [key: string]: (values: any) => void } = {
    statuses: setSelectedStatuses,
    types: setSelectedTypes,
    tags: setSelectedTags,
    search: setSearchTerm,
    tagSearch: setTagSearchTerm,
    isFavorites: setIsFavorites,
  };

  // eslint-disable-next-line
  function onFilterChange(filterName: string, values: any) {
    filterChangeCallbacks[filterName](values);
  }

  const actions = manageAudienceAccess
    ? [
        {
          label: 'Audience',
          to: './new',
          icon: <Icon iconName="Plus" iconType="SVG" useCurrentColor />,
        },
      ]
    : [];

  return (
    <ListPage
      title="People"
      breadcrumbs={[{ label: 'People' }]}
      tabs={[
        { to: 'audiences', label: 'Audiences' },
        { to: 'users', label: 'Users' },
      ]}
      actions={actions}
      filterbar={
        <FiltersBar
          onChange={onFilterChange}
          tagOptions={tagOptions}
          isFavorites={isFavorites}
          searchTerm={searchTerm}
          tagSearchTerm={tagSearchTerm}
          statuses={selectedStatuses}
          tags={selectedTags}
          types={selectedTypes}
        />
      }
    >
      {errorMessage && <>{errorMessage}</>}
      {data && !errorMessage && (
        <InfiniteContainer parentRef={parentRef}>
          {(height: number) => (
            <AudiencesList
              audiences={data}
              isLoading={isLoading}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              totalRecords={meta?.totalRecords}
              parentRef={parentRef}
              height={height}
              filterConfig={{
                search,
                tags,
                statuses: status,
                types: audienceType,
                favorites: isFavorites,
              }}
            />
          )}
        </InfiniteContainer>
      )}
    </ListPage>
  );
};

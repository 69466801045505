import * as React from 'react';
import { Box } from 'DesignSystem/Components/Box';
import { ReactComponent as LightBulb } from 'shared/icons/LightBulb.svg';
import * as Text from 'DesignSystem/Typography';

import {
  background,
  Border,
  border,
  Body,
  Caption,
  styles,
} from 'DesignSystem/Typography';
import { Icon } from 'shared/Icon';

const BgColors = {
  gray: background.gray05,
  white: background.gray02,
  red: background.redTintLight,
  yellow: background.yellowTintLight,
  brandTint: background.brandTintLight,
};

export enum AlertType {
  success = 'success',
  info = 'info',
  error = 'error',
  warning = 'warning',
}

interface AlertProps {
  type: AlertType;
  message?: React.ReactNode;
  title?: string;
  enableIcon?: boolean;
  icon?: JSX.Element;
  addon?: JSX.Element;
  bgColor?: keyof typeof BgColors;
  compact?: boolean;
  padding?: number;
}

const getIcon = (type: AlertType, compact: boolean) => {
  switch (type) {
    case AlertType.success:
      return <Icon iconName="check-circle" />;
    case AlertType.info:
      // eslint-disable-next-line no-case-declarations
      let width = 18;
      // eslint-disable-next-line no-case-declarations
      let height = 24;
      if (compact) {
        width = 13.5;
        height = 18;
      }
      return <LightBulb width={width} height={height} />;
    case AlertType.error:
      return <Icon iconName="exclamation-triangle" />;
    case AlertType.warning:
      return <Icon iconName="exclamation-triangle" />;
    default:
      return <></>;
  }
};

const getAlertBorder = (type: AlertType): Border[] => {
  switch (type) {
    case AlertType.success:
      return [border.width4, border.greenFull];
    case AlertType.info:
      return [border.width4, border.brandFull];
    case AlertType.error:
      return [border.width4, border.redFull];
    case AlertType.warning:
      return [border.width4, border.orangeFull];
    default:
      return [border.width0];
  }
};

export const Alert: React.FC<AlertProps> = ({
  type,
  title,
  message,
  enableIcon,
  icon,
  addon,
  bgColor = 'gray',
  compact = false,
}) => {
  return (
    <Box
      color={BgColors[bgColor]}
      border={[...getAlertBorder(type), border.solid, border.radius4]}
      style={{
        borderTop: 'none',
        borderBottom: 'none',
        borderRight: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid var(--color-gray05)',
          borderBottom: '1px solid var(--color-gray05)',
          borderRight: '1px solid var(--color-gray05)',
        }}
        minHeight={48}
        padding={compact ? [8, 15] : [16, 21]}
        width="100%"
        radius={[0, 4, 4, 0]}
      >
        {enableIcon && (
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            margin={[0, 10, 0, 0]}
          >
            {icon || getIcon(type, compact)}
          </Box>
        )}
        <Box>
          <Box className={styles.Bold}>
            <Body bold>{title}</Body>
          </Box>
          <Caption color={Text.color.gray60}>{message}</Caption>
        </Box>
      </Box>
      {addon && <Box style={{ marginLeft: 'auto' }}>{addon}</Box>}
    </Box>
  );
};

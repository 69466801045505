import React, { useEffect } from 'react';
import { Optional } from 'utility-types';

// eslint-disable-next-line
export type TableauVizRef = React.Ref<any>;

export interface OptionalTableauVizProps
  extends Optional<TableauEventAttributes> {
  height?: React.CSSProperties['height'];
  width?: React.CSSProperties['width'];
  'hide-tabs'?: true;
  toolbar?: 'hidden';
  device?: 'default' | 'desktop' | 'tablet' | 'phone';
  token?: string;
  'iframe-auth'?: true;
  'disable-url-actions'?: true;
  'hide-edit-button'?: true;
  initialQueryParams?: Tableau.ParsedQueryParameters;
}

export interface TableauVizCustomProps extends OptionalTableauVizProps {
  src: string;
}

const TABLEAU_VIZ_COMPONENT_EVENTS = {
  onCustomMarkContextMenuEvent: 'custommarkcontextmenu',
  onCustomViewLoaded: 'customviewloaded',
  onCustomViewRemoved: 'customviewremoved',
  onCustomViewSaved: 'customviewsaved',
  onCustomViewSetDefault: 'customviewremoved',
  onEditButtonClicked: 'editbuttonclicked',
  onEditInDesktopButtonClicked: 'editindesktopbuttonclicked',
  onFilterChanged: 'filterchanged',
  onFirstInteractive: 'firstinteractive',
  onFirstVizSizeKnown: 'firstvizsizeknown',
  onMarkSelectionChanged: 'markselectionchanged',
  onToolbarStateChanged: 'toolbarstatechanged',
  onTabSwitched: 'tabswitched',
  onParameterChanged: 'parameterchanged',
  onUrlAction: 'urlaction',
  onWorkbookPublished: 'workbookpublished',
  onWorkbookPublishedAs: 'workbookpublishedas',
  onWorkbookReadyToClose: 'workbookreadytoclose',
};
const TableauVizComponent = (
  props: TableauVizCustomProps,
  ref: TableauVizRef
) => {
  // const program = useProgram();
  // eslint-disable-next-line
  const vizRef = React.useRef<any>(null);
  React.useImperativeHandle(ref, () => vizRef.current);
  const {
    src,
    token,
    'hide-tabs': hideTabs,
    'iframe-auth': iframeAuth,
  } = props;

  useEffect(() => {
    if (vizRef.current === null) {
      return () => {};
    }
    const { current } = vizRef;
    const listeners: { [key: string]: ITableauCustomEventHandler<never> } = {};

    Object.keys(TABLEAU_VIZ_COMPONENT_EVENTS).forEach((attribute) => {
      const eventKey =
        TABLEAU_VIZ_COMPONENT_EVENTS[attribute as keyof TableauEventAttributes];
      const handler = props[attribute as keyof TableauEventAttributes];
      if (handler !== undefined) {
        listeners[eventKey] = handler;
        vizRef.current.addEventListener(eventKey, handler);
      }
    });

    return () => {
      if (current !== null) {
        Object.keys(listeners).forEach((k) => {
          current?.removeEventListener(k, listeners[k]);
        });
      }
    };
  }, [vizRef, props]);

  return (
    <tableau-viz
      id="tabViz"
      ref={vizRef}
      toolbar="hidden"
      src={src}
      token={token}
      hide-tabs={hideTabs}
      iframe-auth={iframeAuth}
    >
      <viz-filter field="program_id" value={9387} />
    </tableau-viz>
  );
};

type ITableauCustomEventHandler<T> = (
  event: Tableau.CustomTableauEvent<T>
) => void;

/*
 * Supported Events:
 * https://help.tableau.com/current/api/embedding_api/en-us/docs/embedding_api_event.html#supported-events
 */
interface TableauEventAttributes {
  onCustomMarkContextMenuEvent: ITableauCustomEventHandler<unknown>;
  onCustomViewLoaded: ITableauCustomEventHandler<unknown>;
  onCustomViewRemoved: ITableauCustomEventHandler<unknown>;
  onCustomViewSaved: ITableauCustomEventHandler<unknown>;
  onCustomViewSetDefault: ITableauCustomEventHandler<unknown>;
  onEditButtonClicked: ITableauCustomEventHandler<unknown>;
  onEditInDesktopButtonClicked: ITableauCustomEventHandler<unknown>;
  onFilterChanged: ITableauCustomEventHandler<unknown>;
  onFirstInteractive: ITableauCustomEventHandler<Tableau.FilterEventDetail>;
  onFirstVizSizeKnown: ITableauCustomEventHandler<unknown>;
  onMarkSelectionChanged: ITableauCustomEventHandler<unknown>;
  onToolbarStateChanged: ITableauCustomEventHandler<unknown>;
  onTabSwitched: ITableauCustomEventHandler<unknown>;
  onParameterChanged: ITableauCustomEventHandler<
    Tableau.ParameterChangedEventDetail
  >;
  onUrlAction: ITableauCustomEventHandler<unknown>;
  onWorkbookPublished: ITableauCustomEventHandler<unknown>;
  onWorkbookPublishedAs: ITableauCustomEventHandler<unknown>;
  onWorkbookReadyToClose: ITableauCustomEventHandler<unknown>;
}

interface VizFilterProps {
  field: string;
  value: string | number;
}

// We need to add the <tableau-viz> and <viz-filter> web components to the JSX namespace
// as IntrinsicElements in order for them to be recognized by TypeScript

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['tableau-viz']: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > &
        TableauVizCustomProps;
      ['viz-filter']: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > &
        VizFilterProps;
    }
  }
}

export default React.forwardRef(TableauVizComponent);

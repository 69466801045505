import React from 'react';

import { DisabledSelect } from '../Select';
import { DateRangeSelect } from './DateRangeSelect';
import { DateTimeSelect } from './DateTimeSelect';
import { DurationSelect } from './DurationSelect';
import { StringCustomSelect } from './StringCustomSelect';
import { isRecognizedCriterion } from '../CriterionSelect';

function isAnniversaryLike(criterion: string): boolean {
  return (
    criterion === 'start_date' ||
    criterion === 'birth_date' ||
    criterion === 'startday' ||
    criterion === 'birthday' ||
    criterion === 'promotion_date' ||
    criterion === 'requisition_approval_date'
  );
}

/**
 * needed to filter out year specific presets for FE-8805
 */
function isNewDateOperatorAnniversaryLike(criterion: string): boolean {
  return criterion === 'startday' || criterion === 'birthday';
}

export const DateSelect: React.FC<{
  criterion: string;
  operator?: string;
  onChange: (value: string[]) => void;
  value: string[];
  ignoreYearFlag: boolean;
  ignoreYear: boolean;
  toggleIgnoreYear: () => void;
  newDateOperatorsEnabled?: boolean;
  journeysEnabled?: boolean;
  dobFormat?: string;
}> = ({
  operator,
  value,
  onChange,
  criterion,
  ignoreYearFlag = false,
  ignoreYear = false,
  toggleIgnoreYear = () => {},
  newDateOperatorsEnabled,
  journeysEnabled,
  dobFormat,
}) => {
  const handleOnChange = (e?: string) => {
    const safeE = e || '';
    const tVal: string = ignoreYear ? `${safeE}:ignore_year` : safeE;
    if (e) onChange([tVal]);
  };

  const forceIgnoreYear =
    !!newDateOperatorsEnabled && isNewDateOperatorAnniversaryLike(criterion);

  const options = [
    { value: 'last_week', label: 'Last Week' },
    { value: 'this_week', label: 'This Week' },
    { value: 'next_week', label: 'Next Week' },
    { value: 'last_month', label: 'Last Month' },
    { value: 'this_month', label: 'This Month' },
    { value: 'next_month', label: 'Next Month' },
    { value: 'today', label: 'Today' },
    { value: 'tomorrow', label: 'Tomorrow' },
    { value: 'yesterday', label: 'Yesterday' },
  ];

  const journeyOptions = [
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'today', label: 'Today' },
    { value: 'tomorrow', label: 'Tomorrow' },
    { value: 'last_week', label: 'Last Week' },
    { value: 'last_work_week', label: 'Last Work Week (M-F)' },
    { value: 'this_week', label: 'This Week' },
    { value: 'this_work_week', label: 'This Work Week (M-F)' },
    { value: 'next_week', label: 'Next Week' },
    { value: 'last_month', label: 'Last Month' },
    { value: 'this_month', label: 'This Month' },
    { value: 'next_month', label: 'Next Month' },
    { value: 'last_quarter', label: 'Last Quarter' },
    { value: 'this_quarter', label: 'This Quarter' },
    { value: 'last_year', label: 'Last Year' },
    { value: 'this_year', label: 'This Year' },
    { value: 'last_7_days', label: 'Last 7 Days' },
    { value: 'last_30_days', label: 'Last 30 Days' },
  ];

  // 3 cases:
  // 1. newDateOperatorsEnabled && journeysEnabled && isNewDateOperatorAnniversaryLike
  // 2. newDateOperatorsEnabled && journeysEnabled && !isNewDateOperatorAnniversaryLike
  // 3. non of the above
  let configuredOptions = options; // case 3
  if (
    newDateOperatorsEnabled &&
    journeysEnabled &&
    isNewDateOperatorAnniversaryLike(criterion)
  ) {
    // case 1
    configuredOptions = journeyOptions.filter(
      (option) =>
        !['last_7_days', 'last_30_days', 'last_year', 'this_year'].includes(
          option.value
        )
    );
  } else if (newDateOperatorsEnabled && journeysEnabled) {
    // case 2
    configuredOptions = journeyOptions.filter(
      (option) => !['last_7_days', 'last_30_days'].includes(option.value)
    );
  }

  if (isAnniversaryLike(criterion) && operator === 'is') {
    return (
      <StringCustomSelect
        onChange={onChange}
        value={value}
        options={configuredOptions}
        isDisabled={
          !isRecognizedCriterion(criterion, newDateOperatorsEnabled, dobFormat)
        }
      />
    );
  }
  switch (operator) {
    case 'on':
    case 'since':
    case 'before':
      return (
        <DateTimeSelect
          operator={operator}
          onChange={handleOnChange}
          value={value[0]}
          ignoreYearFlag={ignoreYearFlag}
          ignoreYear={ignoreYear}
          toggleIgnoreYear={toggleIgnoreYear}
          isDisabled={
            !isRecognizedCriterion(
              criterion,
              newDateOperatorsEnabled,
              dobFormat
            )
          }
        />
      );
    case 'between':
      return (
        <DateRangeSelect
          onChange={handleOnChange}
          value={value[0]}
          forceIgnoreYear={forceIgnoreYear}
          isDisabled={
            !isRecognizedCriterion(
              criterion,
              newDateOperatorsEnabled,
              dobFormat
            )
          }
        />
      );
    case 'last':
    case 'in_past_before':
    case 'in_future_after':
      return (
        <DurationSelect
          value={value[0]}
          onChange={handleOnChange}
          ignoreYearFlag={ignoreYearFlag}
          ignoreYear={ignoreYear}
          toggleIgnoreYear={toggleIgnoreYear}
          operator={operator}
          newDateOperatorsEnabled={newDateOperatorsEnabled}
          forceIgnoreYear={forceIgnoreYear}
          isDisabled={
            !isRecognizedCriterion(
              criterion,
              newDateOperatorsEnabled,
              dobFormat
            )
          }
        />
      );
    case 'next':
      return (
        <DurationSelect
          value={value[0]}
          futureDuration
          onChange={handleOnChange}
          ignoreYearFlag={ignoreYearFlag}
          ignoreYear={ignoreYear}
          toggleIgnoreYear={toggleIgnoreYear}
          operator={operator}
          newDateOperatorsEnabled={newDateOperatorsEnabled}
          forceIgnoreYear={forceIgnoreYear}
          isDisabled={
            !isRecognizedCriterion(
              criterion,
              newDateOperatorsEnabled,
              dobFormat
            )
          }
        />
      );
    case 'is': // aka relative
      return (
        <StringCustomSelect
          onChange={onChange}
          value={value}
          options={
            newDateOperatorsEnabled && journeysEnabled
              ? journeyOptions.filter(
                  (option) =>
                    option.value !== 'tomorrow' &&
                    option.value !== 'next_week' &&
                    option.value !== 'next_month'
                )
              : [
                  { value: 'yesterday', label: 'Yesterday' },
                  { value: 'today', label: 'Today' },
                  { value: 'this_week', label: 'This Week' },
                  { value: 'last_week', label: 'Last Week' },
                  { value: 'this_month', label: 'This Month' },
                  { value: 'last_month', label: 'Last Month' },
                  { value: 'this_quarter', label: 'This Quarter' },
                  { value: 'last_quarter', label: 'Last Quarter' },
                  { value: 'this_year', label: 'This Year' },
                  { value: 'last_year', label: 'Last Year' },
                  { value: 'last_30_days', label: 'Last 30 Days' },
                  { value: 'last_7_days', label: 'Last 7 Days' },
                ]
          }
          isDisabled={
            !isRecognizedCriterion(
              criterion,
              newDateOperatorsEnabled,
              dobFormat
            )
          }
        />
      );
    default:
      return <DisabledSelect placeholder={value.join(', ') || 'Value'} />;
  }
};

import { useQuery, useInfiniteQuery } from 'react-query';
import { Design } from 'models/design';
import {
  DesignData,
  DesignsCollectionData,
  FetchProps,
  fetchById,
  fetchStatusById,
  fetchDesigns,
} from 'services/api-design';
import { UseDesign, defaultDesign } from 'contexts/design';
import { useCallback, useState } from 'react';
import { PublisherType } from 'models/library';
import { DefinitionBlock, StringVariableRef } from 'models/publisher/block';
import { diff } from 'deep-object-diff';
import {
  QueryResponse,
  InfiniteQueryResponse,
  nextPageToFetch,
} from './common';
import { usePersistDesign } from './persist-design';
import { useFieldVariables } from './design/useFieldVariables';
import { useCreateTemplateFromLibrary } from './useLibrary';

export const stringVariableRefs: StringVariableRef[] = [
  'author_avatar',
  'author_name',
  'content_permalink',
  'permalink_text',
  'program_accent_color',
  'program_header_image',
  'program_logo_image',
  'program_icon_image',
  'program_theme_color',
  'published_at_iso',
  'text_color',
];

export function mapServerDataToDesigns(
  serverData: DesignsCollectionData
): Array<Design> {
  return serverData.data.map((entity: DesignData) => entity.attributes);
}

export const useDesignsQuery = (
  props: FetchProps
): QueryResponse<Array<Design>> => {
  const { isLoading, error, data, isSuccess } = useQuery<
    DesignsCollectionData,
    Error
  >(['designs', { ...props }], () => fetchDesigns(props), {
    retry: false,
    onError: props.onError,
  });
  return {
    isLoading,
    errorMessage: error?.message,
    isSuccess,
    data: data && mapServerDataToDesigns(data),
  };
};

export const useDesignsInfiniteQuery = (
  props: Omit<FetchProps, 'page'>
): InfiniteQueryResponse<Design> => {
  const { programId, pageSize = 20 } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<DesignsCollectionData, Error>(
    ['designs-infinite', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchDesigns({
        programId,
        pageSize,
        page: pageParam as number,
      }),
    {
      cacheTime: 0,
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const flatData =
    data &&
    data.pages
      .map((batch) => (batch ? mapServerDataToDesigns(batch) : []))
      .flat(1);
  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: flatData || [],
    meta: data?.pages[0].meta,
  };
};

type DesignQueryProps = {
  programId: number;
  id: number | 'new';
};

export const useDesignQuery = (
  props: DesignQueryProps
): QueryResponse<Design> => {
  const { programId, id } = props;
  const { isLoading, error, data } = useQuery<Design, Error>(
    ['design/design', id, programId],
    {
      queryFn: () => fetchById(programId, id),
      cacheTime: 0,
    }
  );

  if (!data) {
    return {
      isLoading,
      errorMessage: error?.message,
      data: undefined,
    };
  }

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useDesignStatusQuery = (
  programId: number,
  id: number | null | undefined,
  shouldPollEndpoint: boolean
): QueryResponse<string> => {
  const designId = id || 'new';
  const { isLoading, error, data } = useQuery<string, Error>(
    ['design/status', id, programId],
    {
      queryFn: () => fetchStatusById(programId, designId),
      refetchInterval: 5000,
      enabled: shouldPollEndpoint,
      refetchOnWindowFocus: true,
    }
  );

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

type useDesignProps = {
  programId: number;
  id: number | 'new';
  redirectOnSave?: boolean;
  publisherType?: PublisherType;
};

type DiffType = {
  blocks: { [key: string]: DefinitionBlock };
  parentSource: string;
  parentType: string;
  parentId: string;
};

function isOnlyparentKeys(diffKeys: string[]): boolean {
  return diffKeys.every((key) =>
    ['parentSource', 'parentType', 'parentId'].includes(key)
  );
}

function isOnlyVariableData(designDiff: DiffType): boolean {
  // the variable-only diff looks like this:
  // { blocks: { '0': { field_data: { author_name: { value: 'test' } } } } }
  const diffKeys = Object.keys(designDiff);
  const blockKeys = Object.keys(designDiff.blocks || {});
  const isOnlyBlockKeys = diffKeys.length === 1 && diffKeys[0] === 'blocks';

  if (!isOnlyBlockKeys) {
    return false;
  }

  return blockKeys.every((blockIndex: string) => {
    const block = designDiff.blocks[blockIndex];

    if (!block || Object.keys(block).length !== 1 || !block.field_data) {
      return false;
    }

    const fieldDataKeys = Object.keys(block.field_data);
    return fieldDataKeys.every((key: string) =>
      stringVariableRefs.includes(key as StringVariableRef)
    );
  });
}

export function useDesignContext(props: useDesignProps): UseDesign {
  const { programId, id, redirectOnSave, publisherType } = props;
  const [design, setDesign] = useState<Design>(defaultDesign);
  const [isModified, setIsModified] = useState(false);
  const fieldVariables = useFieldVariables();
  const [hasLoaded, setHasLoaded] = useState(false);
  const getLibraryTemplate = useCreateTemplateFromLibrary();

  const { isLoading, error, refetch } = useQuery<Design, Error>(
    ['design/design', id, programId],
    async () => {
      if (id === 'new') {
        setDesign(defaultDesign);
        setHasLoaded(true);
        setIsModified(false);
        return defaultDesign;
      }
      const designById = await fetchById(programId, id).then((d: Design) => {
        setDesign({ ...d, meta: fieldVariables });
        setHasLoaded(true);
        setIsModified(false);
        return d;
      });
      return designById;
    },
    {
      retry: false,
    }
  );

  const isModifiedByUser = useCallback(
    (changes: Design): boolean => {
      const designDiff = diff(design, changes) as DiffType;
      const diffKeys = Object.keys(designDiff);

      // we update the parent medatata automatically, it shouldn't trigger a modified state
      if (isOnlyparentKeys(diffKeys)) {
        return false;
      }

      // we update the variable data in useVariableExpander, it shouldn't trigger a modified state
      if (isOnlyVariableData(designDiff)) {
        return false;
      }

      return diffKeys.length > 0;
    },
    [design]
  );

  const update = useCallback(
    (changes: Design) => {
      const updatedDesign = {
        ...design,
        ...changes,
      };
      if (isModifiedByUser(changes)) {
        setIsModified(true);
      }

      setDesign(updatedDesign);
    },
    [design, isModifiedByUser]
  );

  const persistDesign = usePersistDesign({
    currentDesign: design,
    setDesign,
    readOnly: false,
    redirectOnSave,
  });

  const save = useCallback<UseDesign['save']>(
    ({ onSuccess, onError } = {}) => {
      persistDesign.save({
        onSuccess: (data?: DesignData) => {
          if (onSuccess) onSuccess(data);
          setIsModified(false);
        },
        onError,
        design,
      });
    },
    [design, persistDesign]
  );

  const parentType =
    publisherType === PublisherType.topicPages
      ? 'ContentChannelAboutPage'
      : undefined;
  const createFromTemplate = useCallback(
    (t) => {
      update({
        ...defaultDesign,
        blocks: t.asset.template.blocks as DefinitionBlock[],
        styles: t.asset.template.styles,
        name: t.title,
      });
      setHasLoaded(true);
    },
    [update]
  );

  const createFromLibraryTemplate = useCallback(
    (t?: string) => {
      getLibraryTemplate(t).then((template) => {
        createFromTemplate(template);
      });
    },
    [createFromTemplate, getLibraryTemplate]
  );

  return {
    id,
    design,
    update,
    save,
    isProcessing: false,
    error: error?.message,
    refetch,
    status: {
      isSaving: false,
      isLoading,
      hasLoaded,
      isModified,
    },
    active: true,
    parentType,
    createFromTemplate,
    createFromLibraryTemplate,
  };
}

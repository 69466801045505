import React, { createContext, useContext, useState } from 'react';
import { Design } from 'models/design';
import { useJourneyState } from 'contexts/journeys/journey';
import { useFeatureFlagsQuery } from 'hooks/feature-flags';
import { useProgram } from 'contexts/program';
import { DeliveryChannel } from 'models/journeys/journey';
import { DrawerState } from '../JourneyDrawer/drawer';

type JourneyContentDesignerContextProps = {
  isDesignerOpen: boolean;
  closeDesigner: (designId?: number) => void;
  onSelectDesign: (design: Design) => void;
  createDesign: () => void;
  editDesign: () => void;
  inlineEditingEnabled: boolean;
  designerId?: number | 'new';
  setDesignerId: (id: number | 'new') => void;
  isTemplateModalOpen: boolean;
  setIsTemplateModalOpen: (value: boolean) => void;
};

const JourneyContentDesignerContext = createContext<
  JourneyContentDesignerContextProps
>({
  isDesignerOpen: false,
  closeDesigner: () => {},
  onSelectDesign: () => {},
  createDesign: () => {},
  editDesign: () => {},
  inlineEditingEnabled: false,
  designerId: 'new',
  setDesignerId: () => {},
  isTemplateModalOpen: false,
  setIsTemplateModalOpen: () => {},
});

export const JourneyContentDesignProvider: React.FC = ({ children }) => {
  const [isDesignerOpen, setIsDesignerOpen] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = React.useState(false);
  const [designerId, setDesignerId] = React.useState<number | 'new'>('new');
  const { id: programId } = useProgram();
  const inlineEditingEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Journeys.InlineEditing'
  ).data?.value;

  const { updateStep, activeStep, setDrawerState } = useJourneyState();

  const onSelectDesign = React.useCallback(
    (design: Design) => {
      if (!activeStep || activeStep.type !== 'communication') return;
      let updatedChannels: DeliveryChannel[] = activeStep.channels;
      if (activeStep.designId !== Number(design.id)) {
        updatedChannels = activeStep.channels.map((c) =>
          c.name === 'email'
            ? {
                ...c,
                previewText: design.name || '',
                subject: design.name || '',
              }
            : c
        );
      }

      updateStep({
        ...activeStep,
        approved: false,
        channels: updatedChannels,
        designId: Number(design.id),
        isDesignModified: true,
      });
      setDesignerId(Number(design.id));
    },
    [activeStep, updateStep]
  );
  const createDesign = () => {
    setDesignerId('new');
    setIsDesignerOpen(true);
  };

  const ensureDesignerId = (designId?: number) => {
    if (!activeStep || activeStep.type !== 'communication') return;
    if (designId) {
      setDesignerId(designId);
    } else if (activeStep.designId) {
      setDesignerId(Number(activeStep.designId));
    } else {
      setDesignerId('new');
    }
  };
  const editDesign = () => {
    ensureDesignerId();
    setIsDesignerOpen(true);
  };
  const closeDesigner = (designId?: number) => {
    setIsDesignerOpen(false);
    ensureDesignerId(designId);
    setDrawerState(DrawerState.Partial);
  };

  return (
    <JourneyContentDesignerContext.Provider
      value={{
        isDesignerOpen,
        closeDesigner,
        onSelectDesign,
        createDesign,
        editDesign,
        inlineEditingEnabled,
        designerId,
        setDesignerId,
        isTemplateModalOpen,
        setIsTemplateModalOpen,
      }}
    >
      {children}
    </JourneyContentDesignerContext.Provider>
  );
};

export const useJourneyContentDesigner: () => JourneyContentDesignerContextProps = () => {
  const context = useContext(JourneyContentDesignerContext);
  if (!context) {
    throw new Error(
      'useJourneyContentDesigner must be used within a JourneyContentDesignerProvider'
    );
  }
  return context;
};

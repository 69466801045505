import * as React from 'react';
import cx from 'classnames';
import { useJourneyState } from 'contexts/journeys/journey';
import { DateRangeInputActionsType, DateRangeInputStateType } from './types';
import { RelativeRangePanel } from './RelativeRangePanel';
import { SummaryControl } from './SummaryControl';
import { ClearButton } from './ClearButton';
import { DoneButton } from './DoneButton';
import { MonthlyCalendars } from './MonthlyCalendars';
import styles from './component-layout.module.css';
import { TimezonePanel } from './TimezonePanel';
import { TimezoneButton } from './TimezoneButton';

type PropsType = {
  actions: DateRangeInputActionsType;
  allowEmpty: boolean;
  allowFutureSelection: boolean;
  className?: string;
  state: DateRangeInputStateType;
  shouldUseTimezone?: boolean;
  forceIgnoreYear?: boolean;
};

export const ComponentLayout: React.FC<PropsType> = (props) => {
  const {
    actions,
    allowEmpty,
    allowFutureSelection,
    className,
    state,
    shouldUseTimezone = false,
    forceIgnoreYear,
  } = props;
  const [timezonePanelDisplay, setTimezonePanelDisplay] = React.useState<
    string
  >('none');
  const [timezoneLayout, setTimezoneLayout] = React.useState<string>(
    styles.layout
  );

  const handleTimezoneToggle = () => {
    setTimezonePanelDisplay(timezonePanelDisplay === 'none' ? 'block' : 'none');
    setTimezoneLayout(
      timezoneLayout === styles.layout ? styles.timezoneLayout : styles.layout
    );
  };
  const observer = React.useRef<HTMLDivElement>(null);
  const initialHeight = React.useRef<number>();
  const rect = observer.current?.getBoundingClientRect();

  const { currentGraph } = useJourneyState();
  const ifMaxStyleNotNeeded = !currentGraph;

  const maxHeightStyle = React.useMemo(() => {
    if (!rect || ifMaxStyleNotNeeded) return undefined;

    if (!initialHeight.current) initialHeight.current = rect.height;
    const calculatedHeight =
      (window.innerHeight || document.documentElement.clientHeight) -
      rect.y -
      10;
    if (calculatedHeight < initialHeight.current) {
      return {
        maxHeight: calculatedHeight,
        overflow: 'auto',
      } as React.CSSProperties;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rect?.y]);

  return (
    <div
      className={cx(timezoneLayout, className)}
      ref={observer}
      style={maxHeightStyle}
    >
      {shouldUseTimezone && (
        <div
          className={styles.range}
          style={{ display: `${timezonePanelDisplay}` }}
        >
          <TimezonePanel
            onChange={actions.setTimezone}
            allowFutureSelection={allowFutureSelection}
            selectedPresetKey={state.timezone.key}
          />
        </div>
      )}
      <div className={styles.range}>
        <RelativeRangePanel
          onChange={actions.setDateRange}
          allowFutureSelection={allowFutureSelection}
          selectedPresetKey={state.dateRange.key}
          forceIgnoreYear={forceIgnoreYear}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.summary}>
          <SummaryControl
            dateRange={state.dateRange}
            selectedDate={state.selectedDate}
            actions={actions}
          />
        </div>
        <div className={styles.calendars}>
          <MonthlyCalendars
            calendarMonth={state.calendarMonth}
            dateRange={state.dateRange}
            selectedDate={state.selectedDate}
            allowFutureSelection={allowFutureSelection}
            actions={actions}
          />
        </div>
        <div className={styles.buttons}>
          {shouldUseTimezone && (
            <TimezoneButton
              onClick={handleTimezoneToggle}
              timezone={state.timezone}
            />
          )}
          <DoneButton onClick={actions.onDone} />
          {!allowEmpty ? null : <ClearButton onClick={actions.onClear} />}
        </div>
      </div>
    </div>
  );
};

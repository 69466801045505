import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import qs from 'qs';

import { datadogRum } from '@datadog/browser-rum';

import { ExternalVideo, SecureVideo, Video } from 'models/video';
import { Image } from 'models/image';
import { ContainedVideo, defaultContainedVideo } from 'models/content';
import { AttachmentData } from 'models/attachment';
import { Source } from 'components/publisher/blocks/forms/fields/shared/SourceMenu';
import { request } from './api-shared';
import { fqApiUrl } from './common';
import {
  defaultFontData,
  FontData,
  FontServerData,
  FontStylesheetData,
} from '../models/font';
import { headers, parseLinkResponse, parseResponse } from './helpers/json-api';
import { UploadProgress, uploadToS3 } from './helpers/upload-to-s3';

export type ApiResponse<T> = { data: { attributes: T } };

export type UploadAttachmentProps = {
  file: File;
  programId: number;
};

async function prepareAttachmentFile({
  file,
  programId,
}: UploadAttachmentProps): Promise<string> {
  // get upload URL
  const uploadUrlResponse = await request(
    fqApiUrl(
      `samba/programs/${programId}/content_attachment_uploads/upload_url`
    ),
    {
      body: JSON.stringify({
        file_name: file.name,
        file_type: file.type,
      }),
      method: 'POST',
    }
  );

  const { uploadUrl, suggestedFileType } = await parseResponse<{
    uploadUrl: string;
    suggestedFileType: string;
  }>(uploadUrlResponse).catch((e) => {
    throw e;
  });

  await uploadToS3({
    url: uploadUrl,
    file,
    suggestedFileType,
  });

  return uploadUrl;
}

export async function uploadContentAttachment({
  file,
  programId,
}: UploadAttachmentProps): Promise<AttachmentData> {
  const uploadUrl = await prepareAttachmentFile({ file, programId });

  // record the URL + get status
  const attachmentResponse = await request(
    fqApiUrl(
      `samba/programs/${programId}/content_attachment_uploads/host_attachment`
    ),
    {
      body: JSON.stringify({
        url: uploadUrl,
        program_id: programId,
        file: {
          original_filename: file.name,
          size: file.size,
          content_type: file.type,
        },
      }),
      method: 'POST',
    }
  );

  const attachmentData = await parseResponse<ApiResponse<AttachmentData>>(
    attachmentResponse
  );
  return attachmentData.data.attributes;
}

export async function uploadDesignAttachment({
  file,
  programId,
}: UploadAttachmentProps): Promise<AttachmentData> {
  const uploadUrl = await prepareAttachmentFile({ file, programId });

  // record the URL + get status
  const attachmentResponse = await request(
    fqApiUrl(`v2/programs/${programId}/design_attachments`),
    {
      body: JSON.stringify({
        url: uploadUrl,
        program_id: programId,
        filename: file.name,
        filesize: file.size,
        content_type_header: determineContentType(file),
      }),
      method: 'POST',
    }
  );

  const attachmentData = await parseResponse<ApiResponse<AttachmentData>>(
    attachmentResponse
  );
  return attachmentData.data.attributes;
}

// hack for IE because windows doesn't natively support most file types :( :( :(
function determineContentType(file: File) {
  if (!navigator.userAgent.includes('Windows')) return file.type;

  switch (file.name.split('.').pop()) {
    case 'ics':
      return 'text/calendar';
    case 'ppt':
    case 'pptx':
      return 'application/vnd.ms-powerpoint';
    case 'doc':
    case 'docx':
      return 'application/msword';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.ms-excel';
    default:
      return file.type;
  }
}

export async function uploadFont(
  props: UploadAttachmentProps
): Promise<FontData> {
  const fontProcessingErrorMessage =
    'Upload failed. Check that the file is valid and under 30MB.';

  const { file, programId } = props;

  // get upload URL
  const uploadUrlResponse = await request(
    fqApiUrl(`samba/programs/${programId}/fonts/upload_url`),
    {
      body: JSON.stringify({
        file_name: file.name,
        file_type: file.type,
      }),
      method: 'POST',
    }
  );

  const uploadUrlData = await parseResponse<{
    uploadUrl: string;
    suggestedFileType: string;
  }>(uploadUrlResponse).catch((e) => {
    throw e;
  });
  const { uploadUrl, suggestedFileType } = uploadUrlData;

  await uploadToS3({
    url: uploadUrl,
    file,
    suggestedFileType,
  });

  // record the URL + get status
  const attachmentResponse = await request(
    fqApiUrl(`samba/programs/${programId}/fonts/host_font`),
    {
      body: JSON.stringify({
        url: uploadUrl,
        program_id: programId,
        file: {
          original_filename: file.name,
          size: file.size,
          content_type: file.type,
        },
      }),
      method: 'POST',
    }
  );

  const attachmentData = await parseResponse<ApiResponse<FontServerData>>(
    attachmentResponse
  ).catch(() => {
    throw new Error(fontProcessingErrorMessage);
  });

  const { attributes } = attachmentData.data;
  return {
    ...defaultFontData,
    url: attributes.output.hostedUrl,
    assetKey: attributes.assetKey,
    status: attributes.status,
    family:
      attributes.output.metadata?.preferredFamily ??
      attributes.output.metadata?.fontFamily,
  };
}

export async function uploadFontStylesheet(
  props: UploadAttachmentProps
): Promise<FontStylesheetData> {
  const { file, programId } = props;

  // get upload URL
  const uploadUrlResponse = await request(
    fqApiUrl(`samba/programs/${programId}/fonts/upload_url`),
    {
      body: JSON.stringify({
        file_name: file.name,
        file_type: file.type,
      }),
      method: 'POST',
    }
  );

  const uploadUrlData = await parseResponse<{
    uploadUrl: string;
    suggestedFileType: string;
  }>(uploadUrlResponse).catch((e) => {
    throw e;
  });
  const { uploadUrl, suggestedFileType } = uploadUrlData;

  await uploadToS3({
    url: uploadUrl,
    file,
    suggestedFileType,
  });

  // record the URL + get status
  const stylesheetResponse = await request(
    fqApiUrl(`samba/programs/${programId}/fonts/host_font_stylesheet`),
    {
      body: JSON.stringify({
        url: uploadUrl,
        program_id: programId,
        file: {
          original_filename: file.name,
          size: file.size,
          content_type: file.type,
        },
      }),
      method: 'POST',
    }
  );

  const stylesheetData = await parseResponse<ApiResponse<FontServerData>>(
    stylesheetResponse
  );

  const { attributes } = stylesheetData.data;
  return {
    ...defaultFontData,
    url: attributes.output.hostedUrl,
    assetKey: attributes.assetKey,
    status: attributes.status,
  };
}

export async function deleteFont(props: {
  assetKey: string;
  programId: number;
}): Promise<{ status: string }> {
  const { assetKey, programId } = props;

  const deleteFontResponse = await request(
    fqApiUrl(
      `samba/programs/${programId}/fonts/destroy_font?asset_key=${assetKey}`
    ),
    {
      method: 'DELETE',
    }
  );

  const deleteFontData = await parseResponse<{
    status: string;
  }>(deleteFontResponse).catch((e) => {
    throw e;
  });

  return { status: deleteFontData.status };
}

export type ShowAttachmentProps = {
  programId: number;
  id: string;
};

export async function showContentAttachment({
  programId,
  id,
}: ShowAttachmentProps): Promise<AttachmentData> {
  const response = await request(
    fqApiUrl(`/samba/programs/${programId}/content_attachment_uploads/${id}`)
  );

  const result = await parseResponse<ApiResponse<AttachmentData>>(response);
  return result.data.attributes;
}

export async function showDesignAttachment({
  programId,
  id,
}: ShowAttachmentProps): Promise<AttachmentData> {
  const response = await request(
    fqApiUrl(`/v2/programs/${programId}/design_attachments/${id}`)
  );

  const result = await parseResponse<ApiResponse<AttachmentData>>(response);
  return result.data.attributes;
}

export async function showFont({
  programId,
  id,
}: ShowAttachmentProps): Promise<FontData> {
  const response = await request(
    fqApiUrl(`/samba/programs/${programId}/fonts/${id}`)
  );

  const result = await parseResponse<ApiResponse<FontData>>(response);
  return result.data.attributes;
}

export type UploadContentImageProps<T> = {
  contentId: number;
  programId: number;
  data: T;
  source: Source;
  getNewContentId(): Promise<number>;
};

export type UploadDesignImageProps<T> = {
  designId: number;
  programId: number;
  data: T;
  source: Source;
  getNewDesignId(): Promise<number>;
};

export type UploadFile = File;
export type UploadUrl = string;

export function isUploadFile(data: unknown): data is UploadFile {
  return (data as UploadFile) instanceof File;
}

// Create the image asset with newly upload image url
// -----
// In a previous version, `uploadContentImageUrl` and `hostImageUrl` were
// the same function. The code would determine which branch to take based
// on the presence or lack of a content id.
//
// One approach was meant for campaigns, the other for templates.
//
// The content_id check is gone and the responsibility for knowing which
// service function to use is placed on the calling hooks. Those must call
// the right implementataion at the right time.
//
// But there is still a chance that the user uploads an image before there
// is a content id to assign the image to.
//
// So this endpoint acknowledges this hiccup in our design by requiring a
// callback that will provide a promise of a content id value to use.
export async function uploadContentImageUrl({
  data: url,
  contentId,
  programId: program_id,
  getNewContentId,
}: UploadContentImageProps<UploadUrl>): Promise<Image> {
  let content_id = contentId;
  if (content_id < 1) content_id = await getNewContentId();
  return parseResponse<Image>(
    await request(
      fqApiUrl(
        `samba/programs/${program_id}/contents/${content_id}/content_images`
      ),
      {
        method: 'POST',
        body: JSON.stringify(snakecaseKeys({ url, program_id, content_id })),
        headers: { 'x-requested-with': 'XMLHttpRequest' },
      }
    )
  );
}

export async function uploadDesignImageUrl({
  data: url,
  designId,
  programId: program_id,
  getNewDesignId,
}: UploadDesignImageProps<UploadUrl>): Promise<Image> {
  let design_id = designId;
  if (design_id < 1) design_id = await getNewDesignId();
  return parseResponse<Image>(
    await request(
      fqApiUrl(
        `api/v2/programs/${program_id}/designs/${design_id}/design_images`
      ),
      {
        method: 'POST',
        body: JSON.stringify(snakecaseKeys({ url, program_id, design_id })),
        headers: { 'x-requested-with': 'XMLHttpRequest' },
      }
    )
  );
}

export type HostImageUrlProps = Omit<
  UploadContentImageProps<UploadUrl>,
  'contentId' | 'getNewContentId'
>;

// create the image asset with newly upload image url
export async function hostImageUrl({
  data: url,
  programId: program_id,
  source,
}: HostImageUrlProps): Promise<Image> {
  return {
    status: 'completed',
    source,
    url: (
      await parseResponse<{ hostedUrl: string }>(
        await request(
          fqApiUrl(`samba/programs/${program_id}/image_uploads/host_image`),
          {
            method: 'POST',
            body: JSON.stringify(snakecaseKeys({ url, program_id })),
            headers: { 'x-requested-with': 'XMLHttpRequest' },
          }
        )
      )
    ).hostedUrl,
  };
}

// get a URL from cloudfront that we can use to upload the image to
async function prepareContentImageFile({
  data,
  programId,
}: Omit<
  UploadContentImageProps<UploadFile>,
  'contentId' | 'getNewContentId'
>): Promise<string> {
  const filename = data.name.replace(/[^a-zA-Z0-9_\-.]/gi, '').toLowerCase();
  const path = `/samba/programs/${programId}/image_uploads/upload_url/${filename}`;
  const response = await request(fqApiUrl(path));
  const { uploadUrl } = await parseResponse<{ uploadUrl: string }>(response);
  await uploadToS3({ url: uploadUrl, file: data });
  return uploadUrl;
}

// get a URL from cloudfront that we can use to upload the image to
async function prepareDesignImageFile({
  data,
  programId,
}: Omit<
  UploadDesignImageProps<UploadFile>,
  'designId' | 'getNewDesignId'
>): Promise<string> {
  const filename = data.name.replace(/[^a-zA-Z0-9_\-.]/gi, '').toLowerCase();
  const path = `/samba/programs/${programId}/image_uploads/upload_url/${filename}`;
  const response = await request(fqApiUrl(path));
  const { uploadUrl } = await parseResponse<{ uploadUrl: string }>(response);
  await uploadToS3({ url: uploadUrl, file: data });
  return uploadUrl;
}

// uploads and creates a campaign relation
export async function uploadContentImageFile({
  data,
  contentId,
  programId,
  getNewContentId,
  source,
}: UploadContentImageProps<UploadFile>): Promise<Image> {
  const uploadUrl = await prepareContentImageFile({ data, programId, source });
  return uploadContentImageUrl({
    data: uploadUrl,
    contentId,
    programId,
    getNewContentId,
    source,
  });
}

export async function uploadDesignImageFile({
  data,
  designId,
  programId,
  getNewDesignId,
  source,
}: UploadDesignImageProps<UploadFile>): Promise<Image> {
  const uploadUrl = await prepareDesignImageFile({ data, programId, source });
  return uploadDesignImageUrl({
    data: uploadUrl,
    designId,
    programId,
    getNewDesignId,
    source,
  });
}

export type HostImageFileProps = Omit<
  Omit<UploadContentImageProps<UploadFile>, 'getNewContentId'>,
  'contentId'
>;

// hosts without an campaign relation
export async function hostImageFile({
  data,
  programId,
  source,
}: HostImageFileProps): Promise<Image> {
  const uploadUrl = await prepareContentImageFile({ data, programId, source });
  return hostImageUrl({ data: uploadUrl, programId, source });
}

export async function fetchContentImage(props: {
  contentId: number;
  programId: number;
  imageId?: string;
}): Promise<Image | undefined> {
  const { imageId, programId, contentId } = props;

  if (!imageId) return undefined;

  const response = await request(
    fqApiUrl(
      `samba/programs/${programId}/contents/${contentId}/content_images/${imageId}`
    )
  );

  return parseResponse<Image>(response);
}

export type ServerLinkData = {
  id: number;
  title: string;
  description: string;
  callToAction: string;
  url: string;
  requestedUrl: string;
  imageUrl: string;
  images: [{ url: string }];
  readTimeInSeconds?: number;
};

export type ServerImagesAvailabilityData = {
  status: 'available' | 'unavailable';
};

export async function fetchLink(url: string): Promise<ServerLinkData> {
  const response = await request(
    fqApiUrl(`link_previews/${encodeURIComponent(url)}`)
  );
  return parseLinkResponse<ServerLinkData>(response);
}

export async function fetchLinkEmbed(
  link: string,
  autoplay = true
): Promise<ExternalVideo> {
  const url = new URL(fqApiUrl(`link_embed/${encodeURIComponent(link)}`));
  url.searchParams.set('autoplay', String(autoplay));
  const response = await request(url.toString(), { authenticate: false });
  return parseLinkResponse<ExternalVideo>(response);
}

export type FetchVideoUploadUrlProps = {
  programId: number;
  filename: string;
};

export async function fetchVideoUploadUrl(
  props: FetchVideoUploadUrlProps
): Promise<string> {
  const { programId, filename } = props;

  const query = qs.stringify({ filename });

  const response = await request(
    fqApiUrl(`samba/programs/${programId}/videos/upload_url?${query}`)
  );

  const result = await parseResponse<{ url: string }>(response);
  return result.url;
}

export async function fetchVideo(props: {
  programId: number;
  isDesignAsset?: boolean;
  videoId?: number;
}): Promise<Video | undefined> {
  const { programId, isDesignAsset, videoId } = props;

  if (!videoId) {
    return undefined;
  }

  const url = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}`
    : `samba/programs/${programId}/videos/${videoId}`;

  const response = await request(fqApiUrl(url));

  return parseResponse<Video>(response);
}

export const cloneVideo = async (props: {
  programId: number;
  videoId: number;
}): Promise<Video> => {
  const { programId, videoId } = props;
  const response = await request(
    fqApiUrl(`samba/programs/${programId}/videos/${videoId}/clone`),
    {
      method: 'POST',
      body: JSON.stringify(
        snakecaseKeys({
          preservePreviewImage: 1,
        })
      ),
      headers,
    }
  );
  return parseResponse<Video>(response);
};

export async function fetchVideoForFeed(props: {
  programId: number;
  isDesignAsset?: boolean;
  videoId?: number;
}): Promise<Video | undefined> {
  const { programId, isDesignAsset, videoId } = props;

  if (!videoId) {
    return undefined;
  }

  const url = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}`
    : `samba/programs/${programId}/videos/${videoId}/show_feed`;

  const response = await request(fqApiUrl(url), { credentials: 'include' });

  if (isDesignAsset) {
    const { secure, ...video } = await parseResponse<SecureVideo>(response);
    video.secureQueryParams = secure?.queryParams;
    return video;
  }

  return parseResponse<Video>(response);
}

export type UploadVideoProps = {
  file: File;
  programId: number;
  isDesignAsset?: boolean;
  parentType?: string;
  videoId?: number;
  onUploadProgress?: (progress: UploadProgress) => void;
};

export async function findOrCreateExternalVideo(
  programId: number,
  url: string,
  autoplay?: boolean,
  isDesignAsset?: boolean,
  parentType?: string
): Promise<Video> {
  const embed = await fetchLinkEmbed(url, autoplay);
  if (embed.embedType !== 'video' || !embed.embedHtml) {
    throw new Error('Unable to extract a video');
  }

  let internalAutoplay;

  if (autoplay === undefined) {
    internalAutoplay = true;
  } else {
    internalAutoplay = autoplay;
  }

  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos`
    : `samba/programs/${programId}/videos`;

  const body = {
    url,
    height: embed.thumbnailHeight,
    width: embed.thumbnailWidth,
    previewImageUrl: embed.thumbnailUrl,
    autoplay: internalAutoplay,
    ...(isDesignAsset && parentType ? { parentType } : undefined),
  };

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(body)),
    headers,
  });
  const result = await parseResponse<ApiResponse<Video>>(response);
  return result.data.attributes;
}

export async function addVideo(props: UploadVideoProps): Promise<number> {
  const { videoId } = props;
  // when mutate is executed, this is a way to know if the added video is a new upload or replacement.
  return videoId ? replaceVideo(props) : uploadVideo(props);
}

export type onImagesAvailabilitySuccess = (
  data: ServerImagesAvailabilityData
) => void;

export async function fetchImagesAvailability(
  urls: Array<string>,
  onSuccess?: onImagesAvailabilitySuccess
): Promise<ServerImagesAvailabilityData> {
  const response = await request(fqApiUrl(`images_availability`), {
    body: JSON.stringify({ images: urls }),
    method: 'POST',
  });
  const json = await response.json();
  if (response.ok) {
    const value = camelcaseKeys(json, { deep: true });
    if (onSuccess) {
      onSuccess(value);
    }

    return value;
  }
  throw new Error(
    'We are unable to access the image for this link due to the link’s settings. Please upload an image or try a different link.'
  );
}

export async function uploadVideo(props: UploadVideoProps): Promise<number> {
  const {
    file,
    programId,
    isDesignAsset,
    parentType,
    onUploadProgress,
  } = props;

  const filename = file.name.replace(/[^\w-_.]/g, '');
  const url = await fetchVideoUploadUrl({ programId, filename });

  datadogRum.addAction('Video upload started');
  const startUploadingTime = Date.now();
  await uploadToS3({ url, file, onUploadProgress });
  datadogRum.addAction('Video upload complete', {
    totalBytes: file.size,
    timeElapsed: Date.now() - startUploadingTime,
  });

  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos`
    : `samba/programs/${programId}/videos`;

  const video: ContainedVideo = {
    ...defaultContainedVideo,
    filename,
    transcodeUrl: url,
  };

  const body = {
    ...video,
    ...(isDesignAsset && parentType ? { parentType } : undefined),
  };

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    body: JSON.stringify(snakecaseKeys(body)),
    headers,
  });

  const result = await parseResponse<{ data: { id: number } }>(response);
  return result.data.id;
}

export async function replaceVideo({
  file,
  programId,
  isDesignAsset,
  parentType,
  videoId,
  onUploadProgress,
}: UploadVideoProps): Promise<number> {
  const filename = file.name.replace(/[^\w-_.]/g, '');
  const url = await fetchVideoUploadUrl({ programId, filename });

  datadogRum.addAction('Video upload started');
  const startUploadingTime = Date.now();
  await uploadToS3({ url, file, onUploadProgress });
  datadogRum.addAction('Video upload complete', {
    totalBytes: file.size,
    timeElapsed: Date.now() - startUploadingTime,
  });

  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}/replace`
    : `samba/programs/${programId}/videos/${videoId}/replace`;

  const video: ContainedVideo = {
    ...defaultContainedVideo,
    filename,
    transcodeUrl: url,
  };

  const body = {
    ...video,
    ...(isDesignAsset && parentType ? { parentType } : undefined),
  };

  const response = await request(fqApiUrl(requestUrl), {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys(body)),
    headers,
  });

  const result = await parseResponse<{ data: { id: number } }>(response);
  return result.data.id;
}

export type UpdateVideoCaptionStylesProps = {
  captionsColor?: string;
  captionsColorBackground?: string;
  captionsFontSize?: number;
  captionsPosition?: 'bottom' | 'top';
};

export type UpdateVideoProps = {
  programId: number;
  isDesignAsset?: boolean;
  parentType?: string;
  videoId: number;
  previewImageUrl?: string;
  defaultShowCaptions?: boolean;
} & UpdateVideoCaptionStylesProps;

export async function updateVideo({
  programId,
  isDesignAsset,
  parentType,
  videoId,
  previewImageUrl,
  defaultShowCaptions,
  captionsColor,
  captionsColorBackground,
  captionsFontSize,
  captionsPosition,
}: UpdateVideoProps): Promise<Video> {
  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}`
    : `samba/programs/${programId}/videos/${videoId}`;

  const body = {
    previewImageUrl,
    defaultShowCaptions,
    captionsColor,
    captionsColorBackground,
    captionsFontSize,
    captionsPosition,
    ...(isDesignAsset && parentType ? { parentType } : undefined),
  };

  const response = await request(fqApiUrl(requestUrl), {
    method: 'PUT',
    body: JSON.stringify(snakecaseKeys(body)),
    headers,
  });

  return parseResponse<Video>(response);
}

import React, { useState } from 'react';
import { Box } from 'DesignSystem/Components';
import { Subheading } from 'DesignSystem/Typography';
import { Pills } from 'DesignSystem/Components/Pills';
import { FormModalSectioned } from 'shared/FormModalSectioned';
import { useFlashMessage } from 'contexts/flasher';
import { ChannelSelection } from '../../channel-selection/channel-selection';
import { CheckedChannelsState } from '../../channel-selection/hooks';
import { DeliverySettings, useDeliveryChannels } from './hooks';
import { ErrorBox } from './ErrorBox';
import { UserPill } from './UserPill';
import { useUser } from '../../../contexts/user';
import { SelectUsers } from './SelectUsers';

export const SendTestModal: React.FC<{
  settings: DeliverySettings;
  initialCheckedChannels: Pick<CheckedChannelsState, 'email'> &
    Partial<Omit<CheckedChannelsState, 'email'>>;
  toggleShow: () => void;
}> = ({ settings, initialCheckedChannels, toggleShow }) => {
  const [users, setUsers] = useState([useUser()]);
  const { setFlashMessage } = useFlashMessage();

  const {
    emailChannel,
    notificationCenterChannel,
    pushChannel,
    isChannelSelected,
    isChannelEnabled,
    toggleChannel,
  } = useDeliveryChannels(settings, initialCheckedChannels, users);

  const channels = [
    emailChannel,
    notificationCenterChannel,
    pushChannel,
  ].filter(({ name }) => isChannelSelected(name));

  const errors = {
    invalidChannels: channels.some(({ valid }) => !valid),
    noSelectedChannels: channels.length === 0,
    noSelectedUsers: users.length === 0,
    usersLimitReached: users.length > 100,
  };

  const resolveFlashMessage = (): string => {
    const [user, ...otherUsers] = users;
    const userName = user.firstName || user.displayName;

    if (otherUsers.length === 0) {
      return `Test messages sent to ${userName}.`;
    }

    return `Test messages sent to ${userName} and ${otherUsers.length} other recipient(s).`;
  };

  const onSubmit = () => {
    const requests = channels.map(({ sendRequest }) => sendRequest());

    Promise.allSettled(requests).then(() => {
      setFlashMessage({
        severity: 'info',
        message: resolveFlashMessage(),
      });
    });

    toggleShow();
  };

  return (
    <FormModalSectioned
      entityText={`test ${settings.deliveryType}`}
      actionText="send"
      submitLabel="Send"
      disabled={Object.values(errors).some(Boolean)}
      onSubmit={onSubmit}
      width={680}
      innerPadding={0}
      onCancel={toggleShow}
    >
      <Box padding={[0, 8]} margin={[-8, 0]}>
        <ChannelSelection
          variant="test-send"
          checked={isChannelSelected}
          toggleChecked={toggleChannel}
        >
          {isChannelEnabled('email') && (
            <ChannelSelection.Email
              subject={emailChannel.subject}
              onSubjectChange={(subject) => emailChannel.setSubject(subject)}
              previewText={emailChannel.previewText}
              onPreviewTextChange={(previewText) =>
                emailChannel.setPreviewText(previewText)
              }
            />
          )}
          {isChannelEnabled('notification_center') && (
            <ChannelSelection.NotificationCenter
              title={notificationCenterChannel.title}
              onTitleChange={(title) =>
                notificationCenterChannel.setTitle(title)
              }
              markAsImportant={notificationCenterChannel.markAsImportant}
              onMarkAsImportantChange={(markAsImportant) =>
                notificationCenterChannel.setMarkAsImportant(markAsImportant)
              }
            />
          )}
          {isChannelEnabled('push') && (
            <ChannelSelection.PushNotification
              pushMessage={pushChannel.message}
              onPushMessageChange={(message) => pushChannel.setMessage(message)}
            />
          )}
          <ErrorBox visible={errors.noSelectedChannels} marginTop={14}>
            {`Please select at least one channel to send test ${settings.deliveryType}`}
          </ErrorBox>
        </ChannelSelection>

        <Box margin={[12, 0, 0, 0]}>
          <Subheading bold>Recipients</Subheading>
          <Box margin={[8, 0, 0, 0]}>
            <SelectUsers selectedUsers={users} onUserChange={setUsers} />
            <ErrorBox visible={errors.usersLimitReached} marginTop={14}>
              {`Limit exceeded: ${users.length} recipient selected. Please select no more than 100 recipients`}
            </ErrorBox>
            <Pills
              values={users}
              render={UserPill}
              onClose={({ id }) =>
                setUsers(users.filter(({ id: uId }) => id !== uId))
              }
              padding={[0, 16, 0, 4]}
            />
          </Box>
          <ErrorBox visible={errors.noSelectedUsers} marginTop={2}>
            Please select at least one recipient to send test{' '}
            {settings.deliveryType}
          </ErrorBox>
        </Box>
      </Box>
    </FormModalSectioned>
  );
};

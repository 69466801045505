import React from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { useProgram } from 'contexts/program';
import { WithPermission } from 'shared/WithPermission';
import { LoadingScreen } from 'shared/LoadingScreen';
import {
  useFeatureFlagsQuery,
  useProgramCustomizationsQuery,
} from 'hooks/feature-flags';
import { Account } from './Account';
import { Branding } from './Branding';
import { ExternalSources } from './ExternalSources';
import { InitiativesListPage } from './Initiatives/InitiativesListPage';
import { StyleGuides } from './StyleGuides';
import { TopicsListPage } from './Topics/TopicsListPage';
import { LandingPage } from './LandingPage/LandingPage';
import { UserExport } from './Content/Users/export';
import { UserImports } from './Content/Users/Import';
import { UserImportView } from './Content/Users/Import/View';
import { EditTopic } from './Topics/EditTopic';
import { NewTopic } from './Topics/NewTopic';
import { EmailAlias } from './EmailAlias';
import { EditInitiative } from './Initiatives/EditInitiative';
import { NewInitiative } from './Initiatives/NewInitiative';
import { EditInclusiveRule } from './StyleGuides/EditInclusiveRule';
import { NewInclusiveRule } from './StyleGuides/NewInclusiveRule';
import { EditExternalSource } from './ExternalSources/EditExternalSource';
import { NewExternalSource } from './ExternalSources/NewExternalSource';
import { ViewAuthorAlias } from './AuthorAlias/ViewAuthorAlias';
import { EditAuthorAlias } from './AuthorAlias/EditAuthorAlias';
import { EditEmailAlias } from './EmailAlias/EditEmailAlias';
import { NewEmailAlias } from './EmailAlias/NewEmailAlias';
import { RolesListPage } from './Roles/RolesListPage';
import { NewRolePage } from './Roles/NewRolePage';
import { EditRolePage } from './Roles/EditRolePage';
import { ViewRolePage } from './Roles/View';
import { Workflows } from './Workflows';
import { AssistantMascot } from './AssistantMascot';
import { CustomHomepage } from './CustomHomepage';
import { Legal } from './Legal';
import { EditSessionOptions } from './SessionOptions/Edit';
import { ViewSessionOptions } from './SessionOptions/View';
import { Shortcuts } from './Shortcuts';
import { Shortcut } from './Shortcuts/view';
import { EditShortcut } from './Shortcuts/edit';
import { LegacyUserAccess } from './LegacyUserAccess';
import { UserAccess } from './UserAccess';
import { UserAccessFirstup } from './UserAccess/UserAccessFirstup';
import { UserAccessSso } from './UserAccess/UserAccessSso';
import { WorkflowTemplates } from './WorkflowTemplates';
import { Microapps } from './Microapps';
import { EditTermsPage } from './Legal/EditTermsPage';
import { EditPolicyPage } from './Legal/EditPolicyPage';
import { EditGdprPage } from './Legal/EditGdrpPage';
import { Capabilities } from './Capabilities';
import { FeatureSettings } from './FeatureSettings';
import { ActivitiesListPage } from './Activities/ActivitiesListPage';
import { NewShortcut } from './Shortcuts/create';
import { CampaignSettingsShow, CampaignSettingsEdit } from './CampaignSettings';
import { EditTopicMainPage } from './Topics/components/EditTopicMainPage';
import { Advocacy } from './Advocacy';
import { CommunityDetails } from './CommunityDetails';
import { AttributeManagement } from './AttributeManagement';
import { EditAttributeManagement } from './AttributeManagement/EditAttributeManagement';
import { EditCommunityDetails } from './CommunityDetails/EditCommunityDetails';
import { EditTopicAboutPage } from './Topics/components/Form/PagesControl/About/EditTopicAboutPage';
import { NewAuthorAlias } from './AuthorAlias/new-alias';
import {
  CustomSlugsShow,
  CustomSlugsEdit,
  CustomSlugsNew,
} from './CustomSlugs';
import { AuthorAliasesListPage } from './AuthorAliases/AuthorAliasesListPage';
import { BulkUpdatesListPage } from './BulkUpdates/BulkUpdatesListPage';
import { RegionDomains } from './RegionDomains';
import { BulkUpdatesDetailsPage } from './BulkUpdates/View';
import { Profile } from './Profile';
import { SearchAttributes as ProfileSearchAttributes } from './Profile/SearchAttributes';
import TopicV2 from './Topics/TopicV2';
import TopicEdit from './Topics/TopicV2/TopicEdit';
import TopicDetails from './Topics/TopicV2/TopicDetails';
import { EditBoxFolder } from './BoxKnowledgeManagement/edit';
import { TopicsSettingsPage } from './Topics/TopicSettingsPage';
import { TopicsSettingsEditPage } from './Topics/TopicSettingsEditPage';
import { CommsAIReviewTerms } from './FeatureSettings/commsai-review-terms';
import { BoxManageFoldersList } from './BoxKnowledgeManagement/BoxManageFoldersList';
import { NewBoxFolder } from './BoxKnowledgeManagement/create';
import { BoxManageContent } from './BoxKnowledgeManagement/manage';
import { BoxConfigurationPage } from './BoxKnowledgeManagement/configure';

const NotFound: React.FC<RouteComponentProps> = () => <div>not found</div>;

export const Configuration: React.FC<RouteComponentProps> = () => {
  const { id: programId } = useProgram();
  const { data: programCustomizations } = useProgramCustomizationsQuery(
    programId
  );
  const styleGuideEnabled = !!useFeatureFlagsQuery(
    programId,
    'StyleGuide.Enabled'
  ).data?.value;
  const kaiframesEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.Parity.Kaiframes'
  ).data?.value;

  const showActivitiesUI = useFeatureFlagsQuery(
    programId,
    'Studio.Activities.UI'
  ).data?.value;

  const bulkUpdatePermissionsEnabled = useFeatureFlagsQuery(
    programId,
    'Studio.BulkUpdatePermissions.UI'
  ).data?.value;

  const showProfileSettingsUI = useFeatureFlagsQuery(
    programId,
    'EE.TagUser.Enabled'
  ).data?.value;

  const showUserAccessUI = useFeatureFlagsQuery(
    programId,
    'Studio.UserAccess.UI'
  ).data?.value;

  const showCommunityDetailsUI = useFeatureFlagsQuery(
    programId,
    'Studio.CommunityDetails.UI'
  ).data?.value;

  const setChannelDefaultsEnabled = useFeatureFlagsQuery(
    programId,
    'SetChannelDefaults.Enabled'
  ).data?.value;

  const topicLandingV2Enabled = useFeatureFlagsQuery(
    programId,
    'EE.TopicLanding.V2.ConfigEnabled'
  ).data?.value;

  const engagementBoostDefaultEnabled = useFeatureFlagsQuery(
    programId,
    'Orchestrate.EngagementBoostDefault.Enabled'
  ).data?.value;

  const {
    data: showPermissionsUI,
    isLoading: showPermissionsLoading,
  } = useFeatureFlagsQuery(programId, 'Studio.Permissions.UI');

  const deliveryThresholdEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.DeliveryThresholdEnabled'
  ).data?.value;

  const customURLsEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Configure.CustomURLsEnabled'
  ).data?.value;

  const authorAliasesEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.Configure.AuthorAliases'
  ).data?.value;

  const displayRegionalDomains = !!useFeatureFlagsQuery(
    programId,
    'Studio.Configure.ShowRegionalDomains'
  ).data?.value;

  const featureSettingsEnabled = !!useFeatureFlagsQuery(
    programId,
    'Studio.FeatureSettings.Enabled'
  ).data?.value;

  const topicLimitsEnabled = useFeatureFlagsQuery(
    programId,
    'EE.UserGeneratedContent.TopicLimitsEnabled'
  ).data?.value;

  const boxIntegrationEnabled = useFeatureFlagsQuery(
    programId,
    'License.Integration.BoxKnowledgeManagement'
  )?.data?.value;

  return (
    <WithPermission permissions={['configureAccess']}>
      <Router>
        {!showCommunityDetailsUI && <Advocacy path="advocacy" />}
        {showCommunityDetailsUI && (
          <>
            <CommunityDetails path="community-details" />
            <EditCommunityDetails path="community-details/edit" />
          </>
        )}
        <AssistantMascot path="assistant-mascot" />
        <Branding path="branding" />
        <Capabilities path="capabilities" />
        {featureSettingsEnabled && (
          <>
            <FeatureSettings path="feature-settings" />
            <CommsAIReviewTerms path="feature-settings/review-terms" />
          </>
        )}
        <CustomHomepage path="custom-homepage" />
        <ExternalSources path="feeds" />
        <LandingPage path="/" />
        <Legal path="legal" />
        <ViewSessionOptions path="session-options" />
        <EditSessionOptions path="session-options/edit/:page" />
        <Shortcuts path="shortcuts" />
        <Shortcut path="shortcuts/:id" />
        <NewShortcut path="shortcuts/new" />
        <EditShortcut path="shortcuts/:id/edit/:page" />
        {showProfileSettingsUI && (
          <>
            <Profile path="profile" />
            <ProfileSearchAttributes path="profile/search-attributes" />
          </>
        )}

        {!showUserAccessUI && <LegacyUserAccess path="user-access" />}
        {showUserAccessUI && (
          <>
            <UserAccess path="user-access" />
            <UserAccessFirstup path="user-access/firstup" />
            {programCustomizations?.canEnableSso?.value && (
              <UserAccessSso path="user-access/sso" />
            )}
          </>
        )}
        <LegacyUserAccess path="user-access" />
        <Microapps path="microapps/*" />
        <UserImports path="imports" />
        <UserImportView path="imports/:id" />
        <EmailAlias path="email-alias" />
        {!showCommunityDetailsUI && <Account path="account/*" />}

        <InitiativesListPage path="initiatives" />
        <TopicsListPage path="topics" />
        {topicLimitsEnabled && (
          <>
            <TopicsSettingsPage path="topics/settings" />
            <TopicsSettingsEditPage path="topics/settings/edit" />
          </>
        )}
        {topicLandingV2Enabled ? (
          <>
            <TopicV2 path="topics/:id">
              <TopicDetails path="details/*" />
              <TopicEdit path="edit/*" />
            </TopicV2>
          </>
        ) : (
          <>
            <EditTopic path="topics/:id/edit">
              <EditTopicMainPage path="/" />
              <EditTopicAboutPage path="about" />
            </EditTopic>
            <NewTopic path="topics/new" />
          </>
        )}
        <EditInitiative path="initiatives/:id/edit" />
        <NewInitiative path="initiatives/new" />
        <NewAuthorAlias path="author-aliases/new" />
        <UserExport path="exports/*" />
        {styleGuideEnabled && (
          <>
            <StyleGuides path="style-guide" />
            <EditInclusiveRule path="style-guide/:id/edit" />
            <NewInclusiveRule path="style-guide/new" />
          </>
        )}

        {(deliveryThresholdEnabled ||
          setChannelDefaultsEnabled ||
          engagementBoostDefaultEnabled) && (
          <>
            <CampaignSettingsShow path="campaign-settings" />
            <CampaignSettingsEdit path="campaign-settings/edit" />
          </>
        )}

        <EditExternalSource path="feeds/:id/edit" />
        <NewExternalSource path="feeds/new" />
        <EditEmailAlias path="email-alias/:id/edit" />

        {customURLsEnabled && (
          <>
            <CustomSlugsShow path="custom-urls" />
            <CustomSlugsEdit path="custom-urls/:id/edit" />
            <CustomSlugsNew path="custom-urls/new" />
          </>
        )}

        <NewEmailAlias path="email-alias/new" />
        {kaiframesEnabled && (
          <>
            <Workflows path="workflows/*" />
            <WorkflowTemplates path="workflow-templates/*" />
          </>
        )}
        {showPermissionsUI?.value && (
          <>
            <RolesListPage path="roles" />
            <NewRolePage path="roles/new" />
            <ViewRolePage path="roles/:id/details" />
            <EditRolePage path="roles/:id/edit/:section" />
          </>
        )}

        <EditTermsPage path="legal/terms" />
        <EditPolicyPage path="legal/policy" />
        <EditGdprPage path="legal/contacts" />

        {showActivitiesUI && <ActivitiesListPage path="activities" />}
        {authorAliasesEnabled && (
          <>
            <ViewAuthorAlias path="author-aliases/:id/details" />
            <EditAuthorAlias path="author-aliases/:id/edit" />
            <AuthorAliasesListPage path="author-aliases" />
          </>
        )}
        {!showPermissionsLoading ? (
          <NotFound default />
        ) : (
          <LoadingScreen default />
        )}
        {bulkUpdatePermissionsEnabled && (
          <>
            <BulkUpdatesListPage path="bulk-updates" />
            <BulkUpdatesDetailsPage path="bulk-updates/:id/details" />
          </>
        )}
        {displayRegionalDomains && <RegionDomains path="region-domains" />}
        <AttributeManagement path="attribute-management" />
        <EditAttributeManagement path="attribute-management/edit" />
        {boxIntegrationEnabled && (
          <>
            <BoxManageFoldersList path="box-manage-folders" />
            <NewBoxFolder path="box-manage-folders/folders/new" />
            <EditBoxFolder path="box-manage-folders/folders/:id/edit" />
            <BoxManageContent path="box-manage-content" />
            <BoxConfigurationPage path="box-configure-app" />
          </>
        )}
      </Router>
    </WithPermission>
  );
};

import React from 'react';
import { useJourneyState } from 'contexts/journeys/journey';
import {
  Steps,
  ConfigurableSteps,
  DelayStepOptions,
  PreviewableSteps,
  JourneyMode,
  Step,
} from 'models/journeys/journey';
import { Pencil } from 'shared/icons';
import { DesignContext } from 'contexts/design';
import { useProgram } from 'contexts/program';
import { useDesignContext } from 'hooks/design';
import cx from 'classnames';
import { Drawer, DrawerState } from './drawer';
import DrawerTabs from './drawer-tabs';
import JourneyDrawerHeader, {
  JourneyDrawerHeaderAction,
} from './journey-drawer-header';
import {
  CommunicationIcon,
  DecisionIcon,
  DelayIcon,
  StartIcon,
} from '../JourneyCanvas/icons';
import { DecisionConfig, ReadOnlyDecisionConfig } from './DecisionConfig';
import styles from './journey-drawer.module.css';
import { CommunicationConfig } from './CommunicationConfig';
import { ReadOnlyCommunicationConfig } from './ReadOnlyCommunicationConfig';
import { DelayConfig, ReadOnlyDelayConfig } from './DelayConfig';
import { ReadOnlyStartConfig, StartConfig } from './StartConfig';
import { ContentListState } from '../JourneyContentListDrawer/Drawer';
import { useJourneyValidationErrors } from '../JourneyErrors/useJourneyValidationErrors';
import { ErrorBanner } from './shared/ErrorBanner';
import { EditableLabel } from './shared/EditableLabel';
import { CommunicationPreview } from './CommunicationPreview';
import { StepReporting } from './StepReporting';
import { useJourneyContentDesigner } from '../JourneyContentDesigner/JourneyContentDesignProvider';
import { JourneyContentDesigner } from '../JourneyContentDesigner';

export type JourneyDrawerTabId = 'config' | 'preview' | 'insights';
export type JourneyDrawerTab = {
  label: string;
  id: string;
};

const CONFIGURE_TAB: JourneyDrawerTab = Object.freeze({
  label: 'Configure',
  id: 'config',
});

const PREVIEW_TAB: JourneyDrawerTab = Object.freeze({
  label: 'Preview',
  id: 'preview',
});

const REPORTING_TAB: JourneyDrawerTab = Object.freeze({
  label: 'Reporting',
  id: 'insights',
});

const stepIcons = {
  decision: DecisionIcon,
  communication: CommunicationIcon,
  delay: DelayIcon,
  start: StartIcon,
};

const CONFIG_COMPONENTS: {
  [Key in keyof ConfigurableSteps]: React.FC<{
    step: ConfigurableSteps[Key];
    onUpdateStep: (step: ConfigurableSteps[Key]) => void;
  }>;
} = {
  decision: DecisionConfig,
  communication: CommunicationConfig,
  delay: DelayConfig,
  start: StartConfig,
};

const READONLY_COMPONENTS: {
  [Key in keyof ConfigurableSteps]: React.FC<{
    step: ConfigurableSteps[Key];
  }>;
} = {
  decision: ReadOnlyDecisionConfig,
  communication: ReadOnlyCommunicationConfig,
  delay: ReadOnlyDelayConfig,
  start: ReadOnlyStartConfig,
};

const PREVIEW_COMPONENTS: {
  [Key in keyof PreviewableSteps]: React.FC;
} = {
  communication: CommunicationPreview,
};

const JourneyDrawerContent: React.FC = () => {
  const {
    drawerState,
    activeStep,
    journeyMode,
    currentGraph,
    setContentListState,
    updateStep,
  } = useJourneyState();
  const { errorsForStep, resolveErrors } = useJourneyValidationErrors();
  const [prevActiveStep, setPrevActiveStep] = React.useState<Step | undefined>(
    activeStep
  );
  const [activeTabId, setActiveTabId] = React.useState<string>('config');
  const {
    editDesign,
    inlineEditingEnabled,
    designerId,
    setDesignerId,
  } = useJourneyContentDesigner();
  const isCommunicationStep = activeStep?.type === 'communication';

  if (drawerState === DrawerState.Closed && activeTabId !== 'config') {
    setActiveTabId('config');
  }
  const { id: programId } = useProgram();
  const designContext = useDesignContext({
    programId,
    id: designerId || 'new',
    redirectOnSave: false,
  });
  if (prevActiveStep !== activeStep) {
    setActiveTabId('config');
    setPrevActiveStep(activeStep);
    if (activeStep?.type === 'communication') {
      setDesignerId(activeStep.designId || 'new');
    }
  }

  const headerActions = React.useMemo<JourneyDrawerHeaderAction[]>(() => {
    const actions = [];
    if (isCommunicationStep && activeStep?.designId && inlineEditingEnabled) {
      actions.push({
        label: 'Edit',
        onClick: () => editDesign(),
        icon: <Pencil />,
      });
    }
    if (isCommunicationStep && journeyMode === JourneyMode.Edit)
      actions.push({
        label: activeStep?.designId
          ? `Replace${inlineEditingEnabled ? '' : ' content'}`
          : `Select${inlineEditingEnabled ? '' : ' content'}`,
        onClick: () => setContentListState(ContentListState.Open),
        secondary: inlineEditingEnabled,
      });
    return actions;
  }, [
    isCommunicationStep,
    activeStep,
    inlineEditingEnabled,
    journeyMode,
    editDesign,
    setContentListState,
  ]);

  const drawerTabs = () => {
    let availableTabs: JourneyDrawerTab[];

    switch (activeStep?.type) {
      case 'communication':
        availableTabs = [CONFIGURE_TAB, PREVIEW_TAB];
        break;
      default:
        availableTabs = [CONFIGURE_TAB];
    }

    return currentGraph?.isLive
      ? [...availableTabs, REPORTING_TAB]
      : availableTabs;
  };

  const getStepUnits = React.useCallback((step) => {
    let label = DelayStepOptions.find(
      (f) => f.value === step.unit
    )?.label.toLowerCase();
    if (label && step.quantity === 1) {
      label = label.slice(0, -1);
    }
    return label;
  }, []);

  const defaultDescription = React.useMemo(() => {
    switch (activeStep?.type) {
      case 'communication':
        return 'Untitled Communication Step';
      case 'decision':
        return 'Split the audience';
      case 'delay':
        return `Wait ${activeStep.quantity} ${getStepUnits(activeStep)}`;
      case 'start':
        return 'Audience configuration and settings';
      case 'end':
        return 'Last communication sent';
      default:
        return '';
    }
  }, [activeStep, getStepUnits]);

  const descriptionComponent = React.useMemo(() => {
    switch (activeStep?.type) {
      case 'communication':
        return journeyMode === JourneyMode.View ? (
          activeStep.title || defaultDescription
        ) : (
          <EditableLabel
            label={activeStep.title || defaultDescription}
            maximum={500}
            onSave={(updated: string) => {
              updateStep({ ...activeStep, title: updated });
              resolveErrors(activeStep.id, ['title']);
            }}
            observable={activeStep}
          />
        );
      case 'decision':
        return journeyMode === JourneyMode.View ? (
          activeStep.name || defaultDescription
        ) : (
          <EditableLabel
            label={activeStep.name || defaultDescription}
            maximum={500}
            onSave={(updated: string) => {
              updateStep({ ...activeStep, name: updated });
              resolveErrors(activeStep.id, ['name']);
            }}
            observable={activeStep}
            mainHeading
          />
        );
      default:
        return defaultDescription;
    }
  }, [activeStep, defaultDescription, journeyMode, resolveErrors, updateStep]);

  const errors =
    activeStep && journeyMode === JourneyMode.Edit
      ? errorsForStep(activeStep.id)
      : undefined;

  if (!activeStep || activeStep.type === 'end') return null;

  const IconComponent = stepIcons[activeStep.type];

  const renderConfigComponent = <T extends keyof ConfigurableSteps>(
    type: T,
    step: Steps[T]
  ) => {
    if (journeyMode === JourneyMode.View) {
      return React.createElement(READONLY_COMPONENTS[type], {
        step,
      });
    }

    return React.createElement(CONFIG_COMPONENTS[type], {
      step,
      onUpdateStep: updateStep,
    });
  };

  const renderPreviewComponent = <T extends keyof PreviewableSteps>(
    type: T,
    designId?: number
  ) => {
    if (designId) return React.createElement(PREVIEW_COMPONENTS[type]);
    return null;
  };

  return (
    <DesignContext.Provider value={designContext}>
      {inlineEditingEnabled && <JourneyContentDesigner />}
      <div className={styles.journeyDrawerScrollable}>
        <JourneyDrawerHeader
          title={activeStep.type}
          description={descriptionComponent}
          icon={<IconComponent />}
          actions={headerActions}
        />
        {errors && <ErrorBanner errors={errors} />}
        <DrawerTabs
          tabs={drawerTabs()}
          activeId={activeTabId}
          onSelectTab={setActiveTabId}
          className={styles.journeyDrawerTabs}
        />
        <div
          className={cx({
            [styles.journeyDrawerTabContent]: activeTabId !== 'preview',
          })}
        >
          {activeTabId === 'config' &&
            renderConfigComponent(activeStep.type, activeStep)}
          {/* TODO: This needs massive refactoring to efficiently handle the different supported draw tab types associated with the components */}
          {activeTabId === 'preview' &&
            activeStep.type === 'communication' &&
            renderPreviewComponent(activeStep.type, activeStep.designId)}
          {activeTabId === 'insights' && <StepReporting step={activeStep} />}
        </div>
      </div>
    </DesignContext.Provider>
  );
};

export const JourneyDrawer: React.FC = () => {
  const { drawerState, setDrawerState } = useJourneyState();

  return (
    <Drawer state={drawerState} onChange={setDrawerState}>
      <JourneyDrawerContent />
    </Drawer>
  );
};

import * as React from 'react';
import { Button } from 'DesignSystem/Form';
import { MAIcon } from 'shared/MAIcon';
import styles from './filters.module.css';

export const NewFilterButton: React.FC<{
  onClick?: () => void;
  layout?: 'campaigns' | 'journeys';
}> = ({ onClick, layout = 'campaigns' }) => (
  <Button
    onClick={onClick}
    text
    compact
    className={layout === 'journeys' ? styles.allFiltersJourneys : undefined}
    icon={
      layout === 'campaigns' ? (
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8334 3.625L8.16669 8.29167V12.9583L5.83335 14.125V8.29167L1.16669 3.625V1.875L12.8334 1.875V3.625Z"
            stroke="currentColor"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </svg>
      ) : (
        <MAIcon name="filter_list" />
      )
    }
    label={<>All Filters</>}
  />
);

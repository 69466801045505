import * as React from 'react';
import { Icon, IconType } from 'shared/Icon';
import {
  EmptyBanner,
  ThumbnailType as ThumbnailPropsType,
  MenuItemsType as MenuItemsPropsType,
} from 'shared/BannerListItem/EmptyBanner';
import { DateTime } from 'luxon';
import styles from '../people.module.css';

export type ThumbnailType = ThumbnailPropsType;
export type MenuItemsType = MenuItemsPropsType;
export type IconStatType = {
  text: string;
} & IconType;

type PropsType = {
  headline: string;
  description?: string;
  thumbnail: ThumbnailType;
  tags?: IconStatType;
  queryType?: string;
  audienceType?: string;
  userCount?: string;
  lastUpdate?: Date;
  createdAt?: Date;
  creator?: string;
  menuItems: MenuItemsType;
  rowId?: string;
};

const IconStat: React.FC<IconStatType & { size: number; className: string }> = (
  props
) => {
  const { text, size, className, ...iconProps } = props;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={className}>
      <Icon size={size} useCurrentColor {...iconProps} />
      <span style={{ marginLeft: 4 }}>{text}</span>
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export const AudienceBanner: React.FC<PropsType> = (props) => {
  const {
    headline,
    description,
    thumbnail,
    menuItems,
    queryType,
    audienceType,
    userCount,
    tags,
    lastUpdate,
    creator,
    createdAt,
    rowId,
  } = props;
  const menuFooter = lastUpdate && (
    <p className={styles.menuFooter}>
      <div>Last updated</div>
      <strong>
        {DateTime.fromJSDate(new Date(lastUpdate)).toFormat('MMM dd, yyyy')}
      </strong>
    </p>
  );
  return (
    <EmptyBanner
      thumbnail={thumbnail}
      menuItems={menuItems}
      menuFooter={menuFooter}
      rowId={rowId}
      aria-label={headline}
    >
      <section className={styles.col1}>
        <div className={styles.title} title={headline}>
          {headline}
        </div>
        <p className={styles.description} title={description}>
          {description}
        </p>
        {tags && (
          /* eslint-disable react/jsx-props-no-spreading */
          <IconStat
            key={tags.text}
            size={14}
            className={styles.description}
            {...tags}
          />
          /* eslint-enable react/jsx-props-no-spreading */
        )}
      </section>
      <div className={styles.statColWrapper}>
        <section className={styles.statCol}>
          <span className={styles.statHeader}>Created</span>
          <span className={styles.stat}>
            {createdAt &&
              DateTime.fromJSDate(new Date(createdAt)).toFormat('MMM dd, yyyy')}
          </span>
          {creator && <p className={styles.stat}>{creator}</p>}
        </section>
        <section className={styles.statCol}>
          <span className={styles.statHeader}>Users</span>
          <span className={styles.stat}>{userCount}</span>
        </section>
        <section className={styles.statCol}>
          <span className={styles.statHeader}>Query Type</span>
          <span className={styles.stat}>{queryType}</span>
        </section>
        <section className={styles.statCol}>
          <span className={styles.statHeader}>Audience Type</span>
          <span className={styles.stat}>{audienceType}</span>
        </section>
      </div>
    </EmptyBanner>
  );
};

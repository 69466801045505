import * as React from 'react';
import { CustomFont, DefinitionBlock, Styling } from 'models/publisher/block';
import { useBlocksEditor } from 'contexts/publisher/compose/blocks';
import { usePublisherStyleEditor } from 'hooks/publisher-style-editor';
import { usePreview } from 'contexts/publisher/compose/preview';
import {
  Post,
  updateCallToAction as updateCallToActionPost,
} from 'models/publisher/post';
import { CallToAction } from 'models/publisher/call-to-action';
import { useCardEditor } from 'hooks/use-card-editor';
import { useNavigationBlocker } from 'contexts/publisher/compose/navigation-blocker';
import { FontOption } from 'models/publisher/style';
import { PublisherType } from 'models/library';

export type DesignEditor = {
  blocksEditor: ReturnType<typeof useBlocksEditor>;
  styleEditor: ReturnType<typeof usePublisherStyleEditor>;
  cardEditor: ReturnType<typeof useCardEditor>;
  preview: ReturnType<typeof usePreview>;
  navigationBlocker: ReturnType<typeof useNavigationBlocker>;
};

export function useCampaignHooks({
  post,
  update,
  fontOptions,
}: {
  post: Post;
  update: (changes: Partial<Post>) => void;
  fontOptions: FontOption[];
}): DesignEditor {
  const updatePostBlocks = React.useCallback(
    (blocks: DefinitionBlock[]) => {
      update({
        blocks,
        styles: {
          ...post.styles,
          fontStylesheets: buildFontStylesheets(
            fontOptions,
            blocks,
            post.styles
          ),
        },
      });
    },
    [fontOptions, post.styles, update]
  );
  const updatePostStyling = React.useCallback(
    (style: Styling) => {
      update({
        styles: {
          ...style,
          fontStylesheets: buildFontStylesheets(
            fontOptions,
            post.blocks,
            style
          ),
        },
      });
    },
    [fontOptions, post.blocks, update]
  );

  const updateCallToAction = React.useCallback(
    (cta: CallToAction) => {
      const updated = updateCallToActionPost({ ...post, callToAction: cta });
      update({ callToAction: updated.callToAction });
    },
    [post, update]
  );

  const blocksEditor = useBlocksEditor(
    post.blocks,
    updatePostBlocks,
    PublisherType.campaigns
  );
  const cardEditor = useCardEditor(post.callToAction, updateCallToAction);

  const styleEditor = usePublisherStyleEditor({
    styleFor: 'global',
    initialStyleData: post.styles,
    onChange: updatePostStyling,
    fontOptions,
  });

  const preview = usePreview(post);
  const navigationBlocker = useNavigationBlocker();
  return {
    blocksEditor,
    styleEditor,
    cardEditor,
    preview,
    navigationBlocker,
  };
}

function buildFontStylesheets(
  fontOptions: FontOption[],
  blocks: DefinitionBlock[],
  styling: Styling
): CustomFont[] {
  const activeFonts = [
    ...Object.values(styling.fonts),
    ...blocks.flatMap((block) => Object.values(block.style_data?.fonts ?? {})),
  ];
  return fontOptions
    .filter((option) => activeFonts.includes(option.value) && option.url)
    .map((option) => ({
      name: option.value,
      url: option.url,
      fallbackFont: option.value,
      generic: option.generic,
    })) as CustomFont[];
}
